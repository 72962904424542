import Utils from '../../../vue-kaiui-adapted/src/utils/Utils'

const setInStorage = (key, value) => {
    Utils.setInStorage(key, value);
}

const getInStorage = (key) => {
    return Utils.getInStorage(key);
}

const startBannerTimeout = () => {
    setTimeout(showAd, 4000)
}


const getAppConfig = (appName) => {
    Utils.getAppConfig(appName)
}

const logAnalyticsPageView = (page, from) => {
    Utils.logAnalyticsPageView(page, from)
}

const openDeatailPage = (wallpaper, route, from, tagName, search, origin, isFromRelated) => {
    let wallpaperWithGoodQuality = wallpaper;
    wallpaperWithGoodQuality.url = wallpaperWithGoodQuality.url.replace(
        "t_media_lib_thumb",
        "c_fill,h_320,w_240"
    );
    wallpaperWithGoodQuality.name =
        wallpaperWithGoodQuality.name.charAt(0).toUpperCase() +
        wallpaperWithGoodQuality.name.slice(1).replaceAll("-", " ");
    let parameters = from ? {
        name: "detail",
        params: { wallpaper: wallpaperWithGoodQuality },
        query: { name: wallpaper.name, from: from, tagname: tagName, search: search, origin: origin, isFromRelated: isFromRelated },
    } : {
        name: "detail",
        params: { wallpaper: wallpaperWithGoodQuality },
        query: { name: wallpaper.name }
    }
    route.push(parameters);
}

const selectFirstItemFromTab = () => {

    document
        .querySelectorAll("[nav-selectable]")[0]
        .setAttribute("nav-index", "0");
    document
        .querySelectorAll("[nav-selectable]")[0]
        .setAttribute("nav-selected", "true");
    document.querySelectorAll("[nav-selectable]")[0].focus();

}

const openAd = () => {
    //ad is defined in lib project as global variable os app
    window.ad.call("click");
}

const logEvent = (category, action, label) => {
    console.log('xxx')
    Utils.logEvent(category, action, label)
}

function showAd() {
    getFeatureFlagValue("mix_full_and_banner_ads") == true ? Utils.loadBanner(Utils.getInStorage('appName'), Utils.getInStorage('publisherId'), 70000)
        : Utils.loadBanner(Utils.getInStorage('appName'), Utils.getInStorage('publisherId'), 30000)
}

function showFullAd() {
    getFeatureFlagValue("mix_full_and_banner_ads") == true ? Utils.loadAdFullScreen(Utils.getInStorage('appName'), Utils.getInStorage('publisherId')) : ''
}

function setWallpaper(arr, callback) {
    return Utils.setWallpaper(arr, function () {
        callback()
    })
}

function getReleatedAppForAd() {
    let releatedApp = Utils.getReleatedAppAd()
    let translation = JSON.parse(releatedApp.translation)
    if (translation[getInStorage('userLanguage')]) {
        releatedApp.translationDescriptionm = translation[getInStorage('userLanguage')]
    }
    else {
        releatedApp.translationDescriptionm = translation['en']
    }
    return releatedApp;
}

function getFeatureFlagValue(key) {
    try {
        var result = Utils.getFeatureFlag(key).value;
        return result
    } catch (error) {
        return false
    }
}


export default {
    setInStorage,
    getInStorage,
    startBannerTimeout,
    getAppConfig,
    logAnalyticsPageView,
    setWallpaper,
    getReleatedAppForAd,
    openDeatailPage,
    selectFirstItemFromTab,
    showFullAd,
    getFeatureFlagValue,
    logEvent
};