import Vue from 'vue';
import Router from 'vue-router';
import Home from '../components/Home';
import Detail from '../components/Detail';
import Search from '../components/Search';
import ListImagesByTag from '../components/ListImagesByTag';
import Empty from '../components/Empty';

Vue.use(Router);

const routes = [
    {
        name: 'home',
        path: '/',
        component: Home,
        alias: ['/lastupdates', '/tag', '/topdownloads', '/all'],
    },
    {
        name: 'detail',
        path: '/detail',
        component: Detail,
    },
    {
        name: 'tag',
        path: '/tag/:name',
        component: ListImagesByTag,
    },
    {
        name: 'search',
        path: '/search',
        component: Search,
    },
    {
        name: 'empty',
        path: '/empty',
        component: Empty,
    },
    {
        name: 'other',
        path: '*',
        component: Home,
    },
];

const router = new Router({ routes, mode: 'history' });

export default router;
