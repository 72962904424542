var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"kaiui-listitem css-selector",staticStyle:{"mix-blend-mode":"normal","box-sizing":"border-box"},style:({
      background:
        'linear-gradient(270.71deg, #' +
        this.topColor +
        ' -24.9%, #' +
        this.bottomColor +
        ' 50.18%)',
    }),attrs:{"nav-selectable":true,"tabindex":"0"},on:{"focus":function($event){return _vm.handleFocusChange(true)},"blur":function($event){return _vm.handleFocusChange(false)},"click":_vm.onClick}},[_c('kaiui-shimmer',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoaded),expression:"!isLoaded"}],staticClass:"img-props shimmer",attrs:{"width":_vm.imageWidth,"height":_vm.imageHeight}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoaded),expression:"isLoaded"}],staticClass:"img-props",style:(_vm.cssProps),attrs:{"width":_vm.imageWidth,"height":_vm.imageHeight,"src":_vm.imageUrl},on:{"load":_vm.onImgLoad}}),_c('div',{staticClass:"text-section",staticStyle:{"justify-content":"space-between","display":"flex","margin":"8px"}},[_c('span',{staticClass:"kaiui-p_pri kaiui-listitem-primary-text"},[_vm._v(_vm._s(_vm.primaryTextWithoutDash))]),_c('div',{staticClass:"slot"},[_vm._t("default")],2),_c('span',{staticClass:"kaiui-p_pri kaiui-listitem-primary-text",staticStyle:{"font-size":"10px","display":"-webkit-box","-webkit-line-clamp":"1","-webkit-box-orient":"vertical","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(_vm._s(_vm.secondaryTextProcessed))])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }