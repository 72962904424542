import { render, staticRenderFns } from "./Dialog.vue?vue&type=template&id=2aaed07c&scoped=true&"
import script from "./Dialog.vue?vue&type=script&lang=js&"
export * from "./Dialog.vue?vue&type=script&lang=js&"
import style0 from "./Dialog.vue?vue&type=style&index=0&id=2aaed07c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../kaios-mobile-wallpaper-pro-2.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2aaed07c",
  null
  
)

export default component.exports