<template>
  <div class="fixer">
    <div v-if="isSearchMode">
      <kaiui-input
        v-model="search"
        :label="$t('MESSAGES.search')"
        maxlength="16"
      ></kaiui-input>
      <kaiui-button
        v-bind:softkeys="softkeysPhone"
        :title="$t('MESSAGES.search')"
        id="wallpaper"
        v-on:softCenter="buscar()"
        v-on:softRight="openAd()"
      />
    </div>
    <div v-else>
      <div
        class="spinnercenter"
        v-if="isLoadingSearch"
        style="display: flex; align-items: center; justify-content: center"
      >
        <b-spinner class="spinner" label="Spinning"></b-spinner>
      </div>

      <kaiui-list-item-with-image
        v-for="(wallpaper, id) in wallpapers"
        :key="id"
        :primaryText="wallpaper.name"
        :secondaryText="wallpaper.tags"
        :imageUrl="wallpaper.url"
        :topColor="wallpaper.topColor"
        :bottomColor="wallpaper.bottomColor"
        v-on:softRight="openAd()"
        v-on:softLeft="back()"
        v-on:softCenter="openDetail(wallpaper)"
        v-bind:softkeys="softkeysPhoneForList"
      ></kaiui-list-item-with-image>
    </div>
    <span
      style="margin: auto"
      v-if="!isSearchMode && !wallpapers.length > 0 && isLoadingSearch == false"
    >
      🔍 📄 ❌ 😢</span
    >
    <kaiui-button
      v-if="isSearchMode"
      v-bind:softkeys="softkeysPhone"
      :title="$t('MESSAGES.back')"
      id="wallpaper"
      v-on:softCenter="backPage()"
      v-on:softRight="openAd()"
      style="margin-top: 16px"
    />
    <kaiui-button
      v-if="!isSearchMode"
      v-bind:softkeys="softkeysPhone"
      :title="$t('MESSAGES.back')"
      id="wallpaper"
      v-on:softCenter="back()"
      v-on:softRight="openAd()"
      style="margin-top: 16px"
    />
  </div>
</template>

<script>
import axios from "axios";
import utils from "../utils/index";
import navigation from "../../../vue-kaiui-adapted/src/navigation/Navigation";

export default {
  components: {},

  data() {
    return {
      search: "",
      isSearchMode: true,
      isLoadingSearch: false,

      softkeysPhone: {
        center: this.$t("MESSAGES.enter"),
        right: this.$t("MESSAGES.ad"),
      },
      softkeysPhoneForList: {
        left: this.$t("MESSAGES.back"),
        center: this.$t("MESSAGES.enter"),
        right: this.$t("MESSAGES.ad"),
      },
      loadImage: false,
      wallpapers: null,
    };
  },
  computed: {
    searchTitle() {
      return "🔍 " + this.search;
    },
  },
  created() {},
  mounted() {
    if (this.$route.query.search != null) {
      this.search = this.$route.query.search;
      this.buscar();
    }
    if (this.$route.query.origin != null) {
      this.$route.query.from = this.$route.query.origin;
    }
    this.$nextTick(function () {
      utils.selectFirstItemFromTab();
      document.getElementById("headertitle").firstElementChild.innerText =
        this.$t("MESSAGES.search");
    });
  },
  methods: {
    openAd() {
      //ad is defined in lib project as global variable os app
      window.ad.call("click");
    },
    buscar() {
      if ((this.search === "") | !this.search) {
      } else {
        this.isSearchMode = false;
        this.isLoadingSearch = true;
        this.wallpapers = [];
        axios
          .get(
            "https://apps.kaios.com.br:9081/api/consumer/search/" + this.search
          )
          .then((response) => {
            this.isLoadingSearch = false;

            this.wallpapers = response.data.result;
            this.$nextTick(function () {
              utils.selectFirstItemFromTab();
            });
            document.getElementById("headertitle").firstElementChild.innerText =
              this.searchTitle;
          })
          .catch(function (error) {
            this.isLoadingSearch = false;
          });
      }
      utils.showFullAd();
    },
    clearSearch() {
      this.search = "";
      this.isSearchMode = true;
    },
    openDetail(wallpaper) {
      utils.openDeatailPage(
        wallpaper,
        this.$router,
        "search",
        null,
        this.search,
        this.$route.query.from
      );
    },
    back() {
      this.$router.push({
        path: "search",
        query: { from: this.$route.query.from },
      });
      this.search = "";
      this.isSearchMode = true;
      document.getElementById("headertitle").firstElementChild.innerText =
        utils.getInStorage("appName");
      this.$nextTick(function () {
        utils.selectFirstItemFromTab();
        navigation.Down();
        navigation.Up();
      });
    },
    backPage() {
      document.getElementById("headertitle").firstElementChild.innerText =
        utils.getInStorage("appName");
      if (!this.$route.query.obj) {
        this.$router.push({
          path: "/",
          query: { from: this.$route.query.from },
        });
      }
    },
  },
};
</script>

<style></style>
