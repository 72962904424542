module.exports = {
  en: {
    MESSAGES: {
      more_apps: "More apps",
      last_updates: "Last Updates",
      tags: "Tags",
      top_downloads: "Top Downloads",
      all: "All",
      back: "Back",
      enter: "Enter",
      set_wallpaper: "Set Wallpaper",
      save_gallery: "Save in Gallery",
      loading_image: "Loading image",
      show_more: "Show more",
      search: "Search",
      you_may_like: "👇 You may like 👇",
      ad: "AD.",
      go_to_end: "Go to end",
      set_ringtone: 'Set as Ringtone',
      download_started: 'Download Started',
      audio_instructions: 'Press 3 to volume | 1 to pause',
      volume: 'Volume',
      moreApps: 'More Apps',
      newWallpaperSucess: '✅　　Success!　　✅　New wallpaper defined!',
      texttop: 'Text Top',
      textbottom: 'Text Bottom',
      share: 'Share',
      up_to_16_chars: 'Up to 16 characters',
      pickAColor: "Select a color",
      textColor: "Text color",
      red: "Red",
      blue: "Blue",
      green: "Green",
      black: "Black",
      white: "White",
      pink: "Pink",
      to_preview: 'To listen',
      open_volume_manager: 'Press 3 to change volume',
      stop_audio: 'Press 1 to stop audio',
    },
  },
  pt: {
    MESSAGES: {
      more_apps: "Mais apps",
      last_updates: "Novidades",
      tags: "Categorias",
      top_downloads: "Mais Baixados",
      all: "Todos",
      back: "Voltar",
      enter: "Entrar",
      set_wallpaper: "Definir como Wallpaper",
      save_gallery: "Salvar na galeria",
      loading_image: "Carregando imagem",
      show_more: "Mostrar mais",
      search: "Buscar",
      you_may_like: "👇 Você pode gostar 👇",
      ad: "AD.",
      go_to_end: "Ir para o final",
      set_ringtone: 'Definir como toque',
      download_started: 'Download Iniciado',
      audio_instructions: 'Aperte 3 p/ volume | 1 p/  pause',
      volume: 'Volume',
      moreApps: 'Mais Apps',
      newWallpaperSucess: '✅　　Sucesso!　　✅　Novo wallpaper definido!',
      show_more: 'Mostrar mais',
      texttop: 'Texto de cima',
      textbottom: 'Texto de baixo',
      share: 'Compartilhar',
      up_to_16_chars: 'Até 16 caracteres',
      pickAColor: "Selecione uma cor",
      textColor: "Cor do texto",
      red: "Vermelho",
      blue: "Azul",
      green: "Verde",
      black: "Preto",
      white: "Branco",
      pink: "Rosa",
      to_preview: 'Para ouvir',
      open_volume_manager: 'Aperte 3 para alterar o volume',
      stop_audio: 'Aperte 1 para parar o áudio',
    },
  },
  ur: {
    // Urdu( for Pakistan)
    MESSAGES: {
      more_apps: "+",
      last_updates: "نوئیڈیڈیز",
      tags: "اقسام",
      top_downloads: "سب سے اوپر ڈاؤن لوڈ",
      all: "تمام مشمولات",
      back: "والٹر",
      enter: "اندراج",
      set_wallpaper: "وال پیپر کی طرح تعریف کریں",
      save_gallery: "گیلری میں محفوظ کریں",
      loading_image: "لوڈنگ امیج",
      show_more: "مزید دکھائیں",
      search: "تلاش کریں",
      you_may_like: "👇 آپ کو پسند ہو سکتا ہے 👇",
      ad: "AD.",
      go_to_end: "نیچے جاؤ",
      set_ringtone: 'رنگ ٹون کے طور پر لگائیں',
      download_started: 'ڈاؤن لوڈ',
      audio_instructions: 'حجم میں 3 دبائیں 1 رکنا',
      volume: 'حجم',
      moreApps: 'زیادہ اطلاقات',
      newWallpaperSucess: '✅　　کامیابی! ✅　نیا وال پیپر سیٹ!',
      texttop: 'اوپر متن',
      textbottom: 'نیچے متن',
      share: 'بانٹنا',
      up_to_16_chars: '16 حرف تک',
      pickAColor: "رنگ منتخب کریں",
      textColor: "متن کا رنگ",
      red: "سرخ",
      blue: "نیلے",
      green: "سبز",
      black: "سیاہ",
      white: "سفید",
      pink: "گلابی",
      to_preview: 'سننے کے لئے',
      open_volume_manager: 'حجم تبدیل کرنے کے لئے 3 دبائیں',
      stop_audio: 'آڈیو روکنے کے لئے 1 دبائیں',
    },
  },
  es: {
    // spanish
    MESSAGES: {
      more_apps: "Más aplicaciones",
      last_updates: "Últimas actualizaciones",
      tags: "Categorías",
      top_downloads: "Más descargados",
      all: "Todas",
      back: "Volver",
      enter: "Entrar",
      set_wallpaper: "Establecer como fondo de pantalla",
      save_gallery: "Guardar en la galería",
      loading_image: "Cargando imagen",
      show_more: "Mostrar más",
      search: "Buscar",
      you_may_like: "👇 Te podría gustar 👇",
      ad: "AD.",
      go_to_end: "Ir al fondo",
      download_started: 'Descarga iniciada',
      audio_instructions: 'Presione 3 para volumen | 1 para pausar',
      volume: 'volumen',
      search: 'Buscar',
      moreApps: 'Más aplicaciones',
      newWallpaperSucess: '✅　　¡Éxito! 　✅ 　¡Nuevo conjunto de fondos de pantalla!',
      texttop: 'Texto superior',
      textbottom: 'Texto inferior',
      share: 'Compatir',
      up_to_16_chars: 'Hasta 16 caracteres',
      pickAColor: "Elige un color",
      textColor: "Color del texto",
      red: "Rojo",
      blue: "Azul",
      green: "Verde",
      black: "Negro",
      white: "Blanco",
      pink: "Rosa",
      to_preview: 'Escuchar',
      open_volume_manager: 'Presione 3 para volumen',
      stop_audio: '1 para pausar'

    },
  },
  sw: {
    // Swahili for Africa
    MESSAGES: {
      more_apps: "+",
      last_updates: "Habari",
      tags: "Makundi",
      top_downloads: "Iliyopakuliwa Zaidi",
      all: "Wote",
      back: "Rudi",
      enter: "Ingia",
      set_wallpaper: "Weka kama Ukuta",
      save_gallery: "Ila picha",
      loading_image: "Inapakua picha",
      show_more: "Onyesha zaidi",
      search: "Tafuta",
      you_may_like: "👇 Unaweza kupenda 👇",
      ad: "AD.",
      go_to_end: "Kwenda chini",
      set_ringtone: 'kuweka sauti za simu',
      download_started: 'Upakuaji umeanza',
      audio_instructions: 'Bonyeza 3 hadi sauti | 1 kusitisha',
      volume: 'ujazo',
      moreApps: 'Programu zaidi',
      newWallpaperSucess: '✅　　Mafanikio! 　✅ 　Seti mpya ya mandhari!',
      texttop: 'Maandishi ya juu',
      textbottom: 'Maandishi ya chini',
      share: 'Kushiriki',
      up_to_16_chars: 'Hadi herufi 16',
      pickAColor: "Chagua rangi",
      textColor: "Rangi ya maandishi",
      red: "Nyekundu",
      blue: "Bluu",
      green: "Kijani",
      black: "Nyeusi",
      white: "Nyeupe",
      pink: "Pink",
      to_preview: 'Kusikiliza',
      open_volume_manager: 'Bonyeza 3 kubadilisha sauti',
      stop_audio: 'Bonyeza 1 kusimamisha sauti'

    },
  },
  fr: {
    MESSAGES: {
      more_apps: "Plus d'applications",
      last_updates: "Dernières mises à jour",
      tags: "Étiquettes",
      top_downloads: "Top téléchargements",
      all: "Tout",
      back: "Retour",
      enter: "Entrer",
      set_wallpaper: "Définir comme fond d'écran",
      save_gallery: "Enregistrer dans la galerie",
      loading_image: "Chargement de l'image",
      show_more: "Afficher plus",
      search: "Recherche",
      you_may_like: "👇 Ça pourrait vous plaire 👇",
      ad: "PUB.",
      go_to_end: "Aller à la fin",
      set_ringtone: 'Définir comme sonnerie',
      download_started: 'Téléchargement commencé',
      audio_instructions: 'Appuyez sur 3 pour le volume | 1 pour pause',
      volume: 'Volume',
      moreApps: 'Plus d\'applications',
      newWallpaperSucess: '✅　　Succès!　　✅　Nouveau fond d\'écran défini!',
      texttop: 'Texte haut',
      textbottom: 'Texte bas',
      share: 'Partager',
      up_to_16_chars: 'Jusqu\'à 16 caractères',
      pickAColor: "Sélectionnez une couleur",
      textColor: "Couleur du texte",
      red: "Rouge",
      blue: "Bleu",
      green: "Vert",
      black: "Noir",
      white: "Blanc",
      pink: "Rose",
      to_preview: 'Pour écouter',
      open_volume_manager: 'Appuyez sur 3 pour changer le volume',
      stop_audio: 'Appuyez sur 1 pour arrêter l\'audio'
    }
  },
  ru: {
    MESSAGES: {
      more_apps: "больше приложений",
      last_updates: "последние обновления",
      tags: "категорииТеги",
      top_downloads: "Популярные загрузки",
      all: "все",
      back: "назад",
      enter: "OK",
      set_wallpaper: "Установка обоев",
      save_gallery: "сохранить в галерее",
      loading_image: "загрузка изображения",
      show_more: "показать больше",
      search: "поиск",
      you_may_like: "👇 тебе может понравиться 👇",
      ad: "AD.",
      go_to_end: "идти до конца",
      set_ringtone: 'установить рингтон',
      download_started: 'загрузка началась',
      audio_instructions: 'Нажмите 3 для увеличения громкости | 1 сделать паузу',
      volume: 'объем',
      moreApps: 'больше приложений',
      newWallpaperSucess: '✅　　Успех! 　✅ 　Новый набор обоев! !',
      texttop: 'Заголовок',
      textbottom: 'Нижний текст',
      share: 'Делиться',
      up_to_16_chars: 'До 16 символов',
      pickAColor: "Выберите цвет",
      textColor: "Цвет текста",
      red: "Красный",
      blue: "Синий",
      green: "Зеленый",
      black: "Черный",
      white: "Белый",
      pink: "Розовый",
      to_preview: 'Для прослушивания',
      open_volume_manager: 'Нажмите 3, чтобы изменить громкость',
      stop_audio: 'Нажмите 1, чтобы остановить аудио'

    },
  },
  de: {
    MESSAGES: {
      more_apps: "Mehr Apps",
      last_updates: "Letzte Updates",
      tags: "Tags",
      top_downloads: "Top Downloads",
      all: "Alles",
      back: "Zurück",
      enter: "Eingeben",
      set_wallpaper: "Als Hintergrund setzen",
      save_gallery: "In Galerie speichern",
      loading_image: "Bild wird geladen",
      show_more: "Mehr anzeigen",
      search: "Suche",
      you_may_like: "👇 Das könnte Ihnen gefallen 👇",
      ad: "ANZEIGE.",
      go_to_end: "Zum Ende gehen",
      set_ringtone: 'Als Klingelton festlegen',
      download_started: 'Download gestartet',
      audio_instructions: 'Drücken Sie 3 für Lautstärke | 1 zum Pausieren',
      volume: 'Lautstärke',
      moreApps: 'Mehr Apps',
      newWallpaperSucess: '✅　　Erfolg!　　✅　Neuer Hintergrund festgelegt!',
      texttop: 'Text oben',
      textbottom: 'Text unten',
      share: 'Teilen',
      up_to_16_chars: 'Bis zu 16 Zeichen',
      pickAColor: "Farbe auswählen",
      textColor: "Textfarbe",
      red: "Rot",
      blue: "Blau",
      green: "Grün",
      black: "Schwarz",
      white: "Weiß",
      pink: "Rosa",
      to_preview: 'Zum Anhören',
      open_volume_manager: 'Drücken Sie 3, um die Lautstärke zu ändern',
      stop_audio: 'Drücken Sie 1, um die Audio-Wiedergabe zu stoppen'
    }
  },
  it: {
    MESSAGES: {
      more_apps: "Più app",
      last_updates: "Ultimi aggiornamenti",
      tags: "Tag",
      top_downloads: "Top download",
      all: "Tutto",
      back: "Indietro",
      enter: "Invio",
      set_wallpaper: "Imposta come sfondo",
      save_gallery: "Salva in galleria",
      loading_image: "Caricamento immagine",
      show_more: "Mostra di più",
      search: "Cerca",
      you_may_like: "👇 Potrebbe piacerti 👇",
      ad: "PUBBLICITÀ.",
      go_to_end: "Vai alla fine",
      set_ringtone: 'Imposta come suoneria',
      download_started: 'Download iniziato',
      audio_instructions: 'Premi 3 per il volume | 1 per pausa',
      volume: 'Volume',
      moreApps: 'Più app',
      newWallpaperSucess: '✅　　Successo!　　✅　Nuovo sfondo impostato!',
      texttop: 'Testo sopra',
      textbottom: 'Testo sotto',
      share: 'Condividi',
      up_to_16_chars: 'Fino a 16 caratteri',
      pickAColor: "Scegli un colore",
      textColor: "Colore del testo",
      red: "Rosso",
      blue: "Blu",
      green: "Verde",
      black: "Nero",
      white: "Bianco",
      pink: "Rosa",
      to_preview: 'Per ascoltare',
      open_volume_manager: 'Premi 3 per cambiare il volume',
      stop_audio: 'Premi 1 per fermare l\'audio'
    }
  },
  nl: {
    MESSAGES: {
      more_apps: "Meer apps",
      last_updates: "Laatste updates",
      tags: "Tags",
      top_downloads: "Top downloads",
      all: "Alles",
      back: "Terug",
      enter: "Enter",
      set_wallpaper: "Instellen als achtergrond",
      save_gallery: "Opslaan in galerij",
      loading_image: "Afbeelding laden",
      show_more: "Meer tonen",
      search: "Zoeken",
      you_may_like: "👇 Dit vind je misschien leuk 👇",
      ad: "ADV.",
      go_to_end: "Ga naar einde",
      set_ringtone: 'Instellen als beltoon',
      download_started: 'Download gestart',
      audio_instructions: 'Druk op 3 voor volume | 1 om te pauzeren',
      volume: 'Volume',
      moreApps: 'Meer Apps',
      newWallpaperSucess: '✅　　Succes!　　✅　Nieuwe achtergrond ingesteld!',
      texttop: 'Tekst boven',
      textbottom: 'Tekst onder',
      share: 'Delen',
      up_to_16_chars: 'Tot 16 tekens',
      pickAColor: "Selecteer een kleur",
      textColor: "Tekstkleur",
      red: "Rood",
      blue: "Blauw",
      green: "Groen",
      black: "Zwart",
      white: "Wit",
      pink: "Roze",
      to_preview: 'Om te luisteren',
      open_volume_manager: 'Druk op 3 om het volume te wijzigen',
      stop_audio: 'Druk op 1 om audio te stoppen'
    }
  },
  ar: {
    MESSAGES: {
      more_apps: "المزيد من التطبيقات",
      last_updates: "آخر التحديثات",
      tags: "الوسوم",
      top_downloads: "أكثر التحميلات",
      all: "الكل",
      back: "عودة",
      enter: "أدخل",
      set_wallpaper: "ضبط كخلفية",
      save_gallery: "حفظ في المعرض",
      loading_image: "تحميل الصورة",
      show_more: "أظهر المزيد",
      search: "بحث",
      you_may_like: "👇 قد تحب 👇",
      ad: "إعلان.",
      go_to_end: "اذهب إلى النهاية",
      set_ringtone: 'ضبط كنغمة',
      download_started: 'بدأ التحميل',
      audio_instructions: 'اضغط 3 للصوت | 1 للإيقاف المؤقت',
      volume: 'الصوت',
      moreApps: 'المزيد من التطبيقات',
      newWallpaperSucess: '✅　　نجاح!　　✅　تم تعيين خلفية جديدة!',
      texttop: 'النص العلوي',
      textbottom: 'النص السفلي',
      share: 'مشاركة',
      up_to_16_chars: 'حتى 16 حرفًا',
      pickAColor: "اختر لونًا",
      textColor: "لون النص",
      red: "أحمر",
      blue: "أزرق",
      green: "أخضر",
      black: "أسود",
      white: "أبيض",
      pink: "وردي",
      to_preview: 'للاستماع',
      open_volume_manager: 'اضغط 3 لتغيير مستوى الصوت',
      stop_audio: 'اضغط 1 لإيقاف الصوت'
    }
  },
  hi: {
    MESSAGES: {
      more_apps: "और अधिक ऐप्स",
      last_updates: "अधिकतम अपडेट",
      tags: "टैग्स",
      top_downloads: "शीर्ष डाउनलोड",
      all: "सभी",
      back: "वापस",
      enter: "प्रवेश करें",
      set_wallpaper: "वॉलपेपर सेट करें",
      save_gallery: "गैलरी में सहेजें",
      loading_image: "छवि लोड हो रही है",
      show_more: "और दिखाएं",
      search: "खोजें",
      you_may_like: "👇 आपको पसंद आ सकता है 👇",
      ad: "विज्ञापन.",
      go_to_end: "अंत में जाएं",
      set_ringtone: 'रिंगटोन के रूप में सेट करें',
      download_started: 'डाउनलोड शुरू हो गया',
      audio_instructions: 'आवाज़ के लिए 3 दबाएं | 1 पौज करने के लिए',
      volume: 'आवाज़',
      moreApps: 'अधिक ऐप्स',
      newWallpaperSucess: '✅　　सफलता!　　✅　नई वॉलपेपर निर्धारित!',
      texttop: 'शीर्ष पाठ',
      textbottom: 'तल पाठ',
      share: 'साझा करें',
      up_to_16_chars: '16 अक्षरों तक',
      pickAColor: "एक रंग चुनें",
      textColor: "पाठ का रंग",
      red: "लाल",
      blue: "नीला",
      green: "हरा",
      black: "काला",
      white: "सफेद",
      pink: "गुलाबी",
      to_preview: 'सुनने के लिए',
      open_volume_manager: 'आवाज़ परिवर्तन करने के लिए 3 दबाएं',
      stop_audio: 'ऑडियो रोकने के लिए 1 दबाएं'
    }
  },
  bn: {
    MESSAGES: {
      more_apps: "আরও অ্যাপ্স",
      last_updates: "সর্বশেষ আপডেট",
      tags: "ট্যাগ",
      top_downloads: "শীর্ষ ডাউনলোড",
      all: "সব",
      back: "ফিরে যান",
      enter: "প্রবেশ করুন",
      set_wallpaper: "ওয়ালপেপার সেট করুন",
      save_gallery: "গ্যালারিতে সংরক্ষণ করুন",
      loading_image: "চিত্র লোড হচ্ছে",
      show_more: "আরও দেখান",
      search: "অনুসন্ধান করুন",
      you_may_like: "👇 আপনি পছন্দ করতে পারেন 👇",
      ad: "বিজ্ঞাপন.",
      go_to_end: "শেষে যান",
      set_ringtone: 'রিংটোন হিসেবে সেট করুন',
      download_started: 'ডাউনলোড শুরু হয়েছে',
      audio_instructions: 'শব্দের জন্য 3 টিপুন | 1 বিরত করতে',
      volume: 'শব্দ',
      moreApps: 'আরও অ্যাপ্স',
      newWallpaperSucess: '✅　　সাফল্য!　　✅　নতুন ওয়ালপেপার সেট করা হয়েছে!',
      texttop: 'উপরের পাঠ',
      textbottom: 'নিচের পাঠ',
      share: 'শেয়ার করুন',
      up_to_16_chars: '16 অক্ষর পর্যন্ত',
      pickAColor: "একটি রঙ নির্বাচন করুন",
      textColor: "টেক্সট রঙ",
      red: "লাল",
      blue: "নীল",
      green: "সবুজ",
      black: "কালো",
      white: "সাদা",
      pink: "গোলাপী",
      to_preview: 'শুনতে',
      open_volume_manager: 'শব্দ পরিবর্তন করতে 3 টিপুন',
      stop_audio: 'অডিও বন্ধ করতে 1 টিপুন'
    }
  },
  ja: {
    MESSAGES: {
      more_apps: "他のアプリ",
      last_updates: "最新の更新",
      tags: "タグ",
      top_downloads: "トップダウンロード",
      all: "すべて",
      back: "戻る",
      enter: "入る",
      set_wallpaper: "壁紙を設定",
      save_gallery: "ギャラリーに保存",
      loading_image: "画像を読み込んでいます",
      show_more: "もっと見る",
      search: "検索",
      you_may_like: "👇 おすすめ 👇",
      ad: "広告.",
      go_to_end: "最後に行く",
      set_ringtone: '着信音として設定',
      download_started: 'ダウンロードが始まりました',
      audio_instructions: '3を押してボリューム調整 | 1で一時停止',
      volume: 'ボリューム',
      moreApps: '他のアプリ',
      newWallpaperSucess: '✅　　成功！　　✅　新しい壁紙が設定されました！',
      texttop: 'テキストトップ',
      textbottom: 'テキストボトム',
      share: '共有',
      up_to_16_chars: '最大16文字',
      pickAColor: "色を選択",
      textColor: "テキストの色",
      red: "赤",
      blue: "青",
      green: "緑",
      black: "黒",
      white: "白",
      pink: "ピンク",
      to_preview: 'プレビュー',
      open_volume_manager: '3を押してボリュームを変更',
      stop_audio: '1を押してオーディオを停止'
    }
  },
  ko: {
    MESSAGES: {
      more_apps: "더 많은 앱",
      last_updates: "최신 업데이트",
      tags: "태그",
      top_downloads: "상위 다운로드",
      all: "모두",
      back: "뒤로",
      enter: "입력",
      set_wallpaper: "배경화면 설정",
      save_gallery: "갤러리에 저장",
      loading_image: "이미지 로딩 중",
      show_more: "더 보기",
      search: "검색",
      you_may_like: "👇 추천 👇",
      ad: "광고.",
      go_to_end: "끝으로 가기",
      set_ringtone: '벨소리로 설정',
      download_started: '다운로드 시작됨',
      audio_instructions: '볼륨을 조절하려면 3 | 1로 일시 중지',
      volume: '볼륨',
      moreApps: '더 많은 앱',
      newWallpaperSucess: '✅　　성공!　　✅　새로운 배경화면이 설정되었습니다!',
      texttop: '텍스트 상단',
      textbottom: '텍스트 하단',
      share: '공유',
      up_to_16_chars: '최대 16자',
      pickAColor: "색상 선택",
      textColor: "텍스트 색상",
      red: "빨강",
      blue: "파랑",
      green: "초록",
      black: "검정",
      white: "하양",
      pink: "핑크",
      to_preview: '미리 듣기',
      open_volume_manager: '볼륨을 변경하려면 3',
      stop_audio: '오디오를 중지하려면 1'
    }
  },
  zh: {
    MESSAGES: {
      more_apps: "更多应用",
      last_updates: "最新更新",
      tags: "标签",
      top_downloads: "热门下载",
      all: "全部",
      back: "返回",
      enter: "进入",
      set_wallpaper: "设置壁纸",
      save_gallery: "保存到相册",
      loading_image: "正在加载图片",
      show_more: "查看更多",
      search: "搜索",
      you_may_like: "👇 你可能喜欢 👇",
      ad: "广告.",
      go_to_end: "滚动到底部",
      set_ringtone: '设为铃声',
      download_started: '开始下载',
      audio_instructions: '按3调整音量 | 按1暂停',
      volume: '音量',
      moreApps: '更多应用',
      newWallpaperSucess: '✅　　成功！　　✅　新壁纸已设定！',
      texttop: '文本顶部',
      textbottom: '文本底部',
      share: '分享',
      up_to_16_chars: '最多16个字符',
      pickAColor: "选择颜色",
      textColor: "文本颜色",
      red: "红色",
      blue: "蓝色",
      green: "绿色",
      black: "黑色",
      white: "白色",
      pink: "粉红色",
      to_preview: '预览',
      open_volume_manager: '按3更改音量',
      stop_audio: '按1停止音频'
    }
  },
  tr: {
    MESSAGES: {
      more_apps: "Daha fazla uygulama",
      last_updates: "Son güncellemeler",
      tags: "Etiketler",
      top_downloads: "En Çok İndirilenler",
      all: "Tümü",
      back: "Geri",
      enter: "Gir",
      set_wallpaper: "Duvar Kağıdı Olarak Ayarla",
      save_gallery: "Galeriye Kaydet",
      loading_image: "Resim yükleniyor",
      show_more: "Daha fazla göster",
      search: "Ara",
      you_may_like: "👇 Hoşunuza gidebilir 👇",
      ad: "REKLAM.",
      go_to_end: "Sona git",
      set_ringtone: 'Zil sesi olarak ayarla',
      download_started: 'İndirme başladı',
      audio_instructions: 'Sesi ayarlamak için 3’e basın | Duraklatmak için 1’e basın',
      volume: 'Ses',
      moreApps: 'Daha Fazla Uygulama',
      newWallpaperSucess: '✅　　Başarılı!　　✅　Yeni duvar kağıdı ayarlandı!',
      texttop: 'Metin Üst',
      textbottom: 'Metin Alt',
      share: 'Paylaş',
      up_to_16_chars: 'En fazla 16 karakter',
      pickAColor: "Bir renk seçin",
      textColor: "Metin rengi",
      red: "Kırmızı",
      blue: "Mavi",
      green: "Yeşil",
      black: "Siyah",
      white: "Beyaz",
      pink: "Pembe",
      to_preview: 'Önizleme',
      open_volume_manager: 'Sesi değiştirmek için 3\'e basın',
      stop_audio: 'Sesi durdurmak için 1\'e basın'
    }
  }
};