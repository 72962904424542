<template>
  <div style="height: 100%" class="fixer">
    <div v-for="(wallpaper, index) in wallpapers" :key="index">
      <kaiui-list-item-with-image
        :primaryText="wallpaper.name"
        :secondaryText="wallpaper.tags"
        :imageUrl="wallpaper.url"
        :topColor="wallpaper.topColor"
        :bottomColor="wallpaper.bottomColor"
        v-on:softLeft="back()"
        v-on:softRight="openAd()"
        v-on:softCenter="openDetail(wallpaper)"
        v-bind:softkeys="softkeysPhone"
      ></kaiui-list-item-with-image>
    </div>
    <div v-if="wallpapers == null" class="spinnercenter">
      <b-spinner
        class="spinner"
        label="Spinning"
        style="margin: auto"
      ></b-spinner>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import utils from "../utils/index";

export default {
  data() {
    return {
      softkeysPhone: {
        left: this.$t("MESSAGES.back"),
        center: this.$t("MESSAGES.enter"),
        right: this.$t("MESSAGES.ad"),
      },
      wallpapers: null,
    };
  },

  methods: {
    keyDownHandler: function (event) {
      console.log(event.key);
      if (event.key == "Backspace") {
        this.back();
      }
    },
    openDetail(wallpaper) {
      utils.openDeatailPage(
        wallpaper,
        this.$router,
        "taglist",
        this.$route.params.name
      );
    },

    openAd() {
      //ad is defined in lib project as global variable os app
      window.ad.call("click");
    },
    back() {
      document.getElementById("headertitle").firstElementChild.innerText =
        utils.getInStorage("appName");
      this.$router.push({ path: "/", query: { from: "tag" } });
    },
  },
  created() {
    window.addEventListener("keydown", this.keyDownHandler);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDownHandler);
  },
  mounted() {
    document.getElementById("headertitle").firstElementChild.innerText =
      this.$route.params.name;
    this.wallpapers = null;
    axios
      .get(
        "https://apps.kaios.com.br:9081/api/consumer/tag/" +
          this.$route.params.name
      )
      .then((response) => {
        this.wallpapers = response.data.wallpapers;
        utils.logAnalyticsPageView(
          "tagDetail",
          "?name=" + this.$route.params.name
        );
        this.$nextTick(function () {
          utils.selectFirstItemFromTab();
        });
      });
    utils.showFullAd();
  },
};
</script>

<style scoped>
</style>
