<template>
  <div>
    <div
      class="kaiui-listitem css-selector"
      v-bind:nav-selectable="true"
      tabindex="0"
      v-on:focus="handleFocusChange(true)"
      v-on:blur="handleFocusChange(false)"
      v-on:click="onClick"
      style="mix-blend-mode: normal; box-sizing: border-box"
    >
      <kaiui-shimmer
        v-show="!isLoaded"
        width="55"
        height="55"
        class="img-props shimmer"
      />
      <img
        v-show="isLoaded"
        class="img-props"
        @load="onImgLoad"
        :src="imageUrl"
      />

      <div class="text-section" style="margin: 5px">
        <span
          class="kaiui-p_pri kaiui-listitem-primary-text"
          style="font-size: 14px"
          >{{ primaryText }}</span
        >
        <span
          class="kaiui-p_pri kaiui-listitem-primary-text"
          style="
            font-size: 10px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          "
          >{{ secondaryText }}</span
        >
        <div class="tag-wrapper" :style="{ 'background-color': '#' + bgColor }">
          <span
            class="kaiui-p_pri kaiui-listitem-primary-text tag"
            style="font-size: 10px; font-weight: 700"
            >{{ cta }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "kaiui-list-item-releated-app",
  data() {
    return { isLoaded: false };
  },
  computed: {},
  props: {
    /**
     * The Softkeys Object
     * @type {{ left: String, center: String, right: String }}
     * @default { center: "Select" }
     */
    softkeys: {
      default: () => ({ center: "Select" }),
      type: Object,
      required: false,
    },
    primaryText: {
      type: String,
      required: true,
    },
    cta: {
      type: String,
      required: true,
    },
    bgColor: {
      type: String,
      required: true,
    },
    secondaryText: {
      type: String,
      required: true,
    },
    imageUrl: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.$on("softkey-left-pressed", () => {
      /**
       * Emit the event `softLeft` when left softkey is selected
       */
      this.$emit("softLeft");
    });
    this.$on("softkey-right-pressed", () => {
      /**
       * Emit the event `softRight` when right softkey is selected
       */
      this.$emit("softRight");
    });
    this.$on("softkey-center-pressed", () => {
      /**
       * Emit the event `softCenter` when center softkey is selected
       */
      this.$emit("softCenter");
    });
  },

  methods: {
    onImgLoad() {
      this.isLoaded = true;
    },
    /**
     * @private
     */
    handleFocusChange(isNowFocused) {
      if (isNowFocused) {
        /**
         * @private
         */
        this.$root.$emit("update-softkeys-register", this);
      } else {
        /**
         * @private
         */
        this.$root.$emit("update-softkeys-unregister");
      }
    },
    /**
     * @private
     */
    onClick() {
      this.handleFocusChange(true);
      /**
       * @private
       */
      this.$root.$emit("set-element-selected", this.$el);
    },
  },
};
</script>

<style scoped>
span {
  color: black !important;
}
span[nav-selected="true"] {
  mix-blend-mode: difference;
  color: white !important;
}
.kaiui-listitem {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-height: 120px;
  max-height: 120px;
  padding: 0 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: 0;
}
.kaiui-listitem[nav-selected="true"] img {
  border: 5px solid var(--header-background-color);
  box-sizing: border-box;
  border-radius: 10px !important;
  background: white;
}

.tag-wrapper {
  display: inline-block;
  padding: 4px 4px;

  color: white;
  border-radius: 4px;
}

.tag {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shimmer {
  border-radius: 10px !important;
}
.kaiui-listitem[nav-selected="true"] .shimmer {
  border: 5px solid var(--header-background-color);
  box-sizing: border-box;
  border-radius: 10px !important;
}

.kaiui-listitem .kaiui-listitem-text-wrapper {
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  flex: 1;
}

.kaiui-listitem .kaiui-listitem-text-wrapper span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.kaiui-listitem[nav-selected="true"] .kaiui-listitem-primary-text {
  color: var(--listitem-selected-text-color);
}
.kaiui-listitem[nav-selected="true"] .kaiui-listitem-icon:before {
  color: var(--listitem-selected-text-color);
}
.kaiui-listitem[nav-selected="true"] .kaiui-listitem-icon.right:before {
  color: var(--listitem-selected-text-color);
}

.kaiui-listitem .kaiui-listitem-icon {
  display: flex;
  align-items: center;
  margin-right: 10px;
  flex-shrink: 0;
}

.kaiui-listitem .kaiui-listitem-icon.right {
  margin-left: auto;
  margin-right: 0;
  font-size: 20px;
  flex-shrink: 0;
  margin-left: 10px;
}
.kaiui-listitem .kaiui-listitem-icon.right:before {
  color: var(--listitem-icon-right-color);
}

.img-props[nav-selected="true"] {
  box-sizing: border-box;
}

.img-props {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin: auto;
  width: 50px;
}

.text-section {
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  margin-top: 16px;
  margin-left: 6px;
  justify-content: center;
  display: flex;
  margin: 5px;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
}
</style>