<template>
  <div>
    <div
      class="kaiui-listitem css-selector"
      v-bind:nav-selectable="true"
      tabindex="0"
      v-on:focus="handleFocusChange(true)"
      v-on:blur="handleFocusChange(false)"
      v-on:click="onClick"
      :style="{
        background:
          'linear-gradient(270.71deg, #' +
          this.topColor +
          ' -24.9%, #' +
          this.bottomColor +
          ' 50.18%)',
      }"
      style="mix-blend-mode: normal; box-sizing: border-box"
    >
      <kaiui-shimmer
        v-show="!isLoaded"
        :width="imageWidth"
        :height="imageHeight"
        class="img-props shimmer"
      />
      <img
        v-show="isLoaded"
        class="img-props"
        :width="imageWidth"
        :height="imageHeight"
        :style="cssProps"
        @load="onImgLoad"
        :src="imageUrl"
      />

      <div
        class="text-section"
        style="justify-content: space-between; display: flex; margin: 8px"
      >
        <span class="kaiui-p_pri kaiui-listitem-primary-text">{{
          primaryTextWithoutDash
        }}</span>
        <div class="slot">
          <slot></slot>
        </div>

        <span
          class="kaiui-p_pri kaiui-listitem-primary-text"
          style="
            font-size: 10px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          "
          >{{ secondaryTextProcessed }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import Shimmer from "./Shimmer.vue";

export default {
  components: { Shimmer },
  name: "kaiui-list-item-with-image",
  data() {
    return {
      isLoaded: false,
    };
  },
  computed: {
    primaryTextWithoutDash() {
      return (
        this.primaryText.charAt(0).toUpperCase() +
        this.primaryText.slice(1).replaceAll("-", " ")
      );
    },
    secondaryTextProcessed() {
      let textProcessed = "";
      this.secondaryText.forEach((element) => {
        textProcessed += "#" + element + " ";
      });
      return textProcessed;
    },
    cssProps() {
      var mapProp = {};
      if (this.inlineSize != null) {
        mapProp["inline-size"] = this.inlineSize;
      }
      mapProp["object-fit"] = this.objectfit;
      return mapProp;
    },
  },
  props: {
    /**
     * The Softkeys Object
     * @type {{ left: String, center: String, right: String }}
     * @default { center: "Select" }
     */
    softkeys: {
      default: () => ({ center: "Select" }),
      type: Object,
      required: false,
    },
    primaryText: {
      default: "",
      type: String,
      required: true,
    },
    secondaryText: {
      type: Array,
      required: true,
    },
    imageUrl: {
      type: String,
      required: true,
    },
    topColor: {
      type: String,
    },
    bottomColor: {
      type: String,
    },
    imageWidth: {
      default: () => 66,
      required: false,
    },
    imageHeight: {
      default: () => 100,
      required: false,
    },
    objectfit: {
      default: () => "cover",
      required: false,
    },
    inlineSize: {
      default: () => null,
      required: false,
    },
    focusExtraObject: {
      type: Object,
      required: false,
    },
  },
  mounted() {
    this.$on("softkey-left-pressed", () => {
      /**
       * Emit the event `softLeft` when left softkey is selected
       */
      this.$emit("softLeft");
    });
    this.$on("softkey-right-pressed", () => {
      /**
       * Emit the event `softRight` when right softkey is selected
       */
      this.$emit("softRight");
    });
    this.$on("softkey-center-pressed", () => {
      /**
       * Emit the event `softCenter` when center softkey is selected
       */
      this.$emit("softCenter");
    });
    this.$on("3", () => {
      console.log("as");
      /**
       * Emit the event `softCenter` when center softkey is selected
       */
      this.$emit("softCenter");
    });
  },

  methods: {
    onImgLoad() {
      this.isLoaded = true;
    },
    /**
     * @private
     */
    handleFocusChange(isNowFocused) {
      if (isNowFocused) {
        /**
         * @private
         */
        this.$root.$emit("update-softkeys-register", this);
      } else {
        /**
         * @private
         */
        this.$root.$emit("update-softkeys-unregister");
      }
    },
    /**
     * @private
     */
    onClick() {
      this.handleFocusChange(true);
      /**
       * @private
       */
      this.$root.$emit("set-element-selected", this.$el);
    },
  },
};
</script>

<style scoped>
.css-selector[nav-selected="true"] {
  background-size: 250% 250% !important;

  -webkit-animation: AnimationName 5s cubic-bezier(0.68, 1.01, 0, 0.62) infinite !important;
  -moz-animation: AnimationName 5s cubic-bezier(0.68, 1.01, 0, 0.62) infinite !important;
  animation: AnimationName 5s cubic-bezier(0.68, 1.01, 0, 0.62) infinite !important;
}
@keyframes AnimationName {
  0% {
    background-position: 0% 100%;
  }

  50% {
    background-position: 100% 100%;
  }

  100% {
    background-position: 0% 100%;
  }
}

span {
  mix-blend-mode: difference;
  color: white !important;
}
.kaiui-listitem {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-height: 120px;
  max-height: 120px;
  padding: 0 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: 0;
}
.kaiui-listitem[nav-selected="true"] img {
  border: 5px solid var(--header-background-color);
  box-sizing: border-box;
  border-radius: 24px !important;
}
.shimmer {
  border-radius: 24px !important;
}
.kaiui-listitem[nav-selected="true"] .shimmer {
  border: 5px solid var(--header-background-color);
  box-sizing: border-box;
  border-radius: 24px !important;
}

.kaiui-listitem[nav-selected="true"] .slot {
  display: flex;
}

.kaiui-listitem .slot {
  display: none;
  flex-direction: column;
}

.kaiui-listitem .kaiui-listitem-text-wrapper {
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  flex: 1;
}

.kaiui-listitem .kaiui-listitem-text-wrapper span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.kaiui-listitem[nav-selected="true"] .kaiui-listitem-primary-text {
  color: var(--listitem-selected-text-color);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.kaiui-listitem[nav-selected="true"] .kaiui-listitem-icon:before {
  color: var(--listitem-selected-text-color);
}
.kaiui-listitem[nav-selected="true"] .kaiui-listitem-icon.right:before {
  color: var(--listitem-selected-text-color);
}

.kaiui-listitem .kaiui-listitem-icon {
  display: flex;
  align-items: center;
  margin-right: 10px;
  flex-shrink: 0;
}

.kaiui-listitem .kaiui-listitem-icon.right {
  margin-left: auto;
  margin-right: 0;
  font-size: 20px;
  flex-shrink: 0;
  margin-left: 10px;
}
.kaiui-listitem .kaiui-listitem-icon.right:before {
  color: var(--listitem-icon-right-color);
}

.img-props[nav-selected="true"] {
  box-sizing: border-box;
}

.img-props {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin: auto;
  min-width: 66px;
  max-height: 100px;
}

.text-section {
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  margin-top: 8px;
  margin-left: 6px;
}
</style>
