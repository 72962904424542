<template>
  <kaiui-tabs>
    <kaiui-tab-item :name="$t('MESSAGES.last_updates')" selected>
      <LastUpdate></LastUpdate>
    </kaiui-tab-item>
    <kaiui-tab-item :name="$t('MESSAGES.tags')">
      <Tags></Tags>
    </kaiui-tab-item>
    <kaiui-tab-item :name="$t('MESSAGES.top_downloads')">
      <TopDownloads></TopDownloads>
    </kaiui-tab-item>
    <kaiui-tab-item :name="$t('MESSAGES.all')">
      <All></All>
    </kaiui-tab-item>
    <kaiui-tab-item :name="tabName" v-if="shouldShowReleatedAppTab">
      <div>
        <kaiui-list-item-releated-app
          v-for="(app, id) in releatedList"
          :key="id"
          :secondaryText="app.translationDescription.ad_description"
          :primaryText="app.translationDescription.ad_title"
          :imageUrl="app.appIconUrl"
          v-on:softCenter="openApp(app.manifestUrl, app.appName)"
          v-on:softRight="openAd()"
          v-bind:softkeys="softkeysPhone"
        ></kaiui-list-item-releated-app>
      </div>
    </kaiui-tab-item>

    <!-- <kaiui-tab-item name="Pre Downloads">
        <PreLoaded></PreLoaded>
      </kaiui-tab-item>-->
  </kaiui-tabs>
</template>

<script>
import LastUpdate from "./LastUpdate";
import TopDownloads from "./TopDownloads";
import All from "./All";
import Tags from "./Tags";
import ListImagesByTag from "./ListImagesByTag";
import Utils from "../../../vue-kaiui-adapted/src/utils/Utils";

export default {
  name: "home",
  components: { LastUpdate, TopDownloads, All, Tags, ListImagesByTag },
  data() {
    return {
      shouldShowReleatedAppTab: false,
      releatedList: Array,
      softkeysPhone: {
        center: this.$t("MESSAGES.enter"),
        right: "AD",
      },
    };
  },
  methods: {
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    openAd() {
      //ad is defined in lib project as global variable os app
      window.ad.call("click");
    },
    goToTab(index) {
      this.$nextTick(function () {
        document
          .querySelectorAll("[tab-selectable]")
          [index].setAttribute("tab-selected", "true");

        document
          .querySelectorAll("[tab-selectable]")[0]
          .setAttribute("tab-selected", "false");

        document.querySelectorAll("[tab-selectable]")[index].click();
      });
    },
    openApp(url, appName) {
      try {
        Utils.logAnalyticsPageView("openedStore", "?appName=" + appName);
      } catch (error) {}
      var activity = new MozActivity({
        name: "open-deeplink",
        data: {
          //The blob object of audio file, must be within in array
          url: "app://kaios-store.kaiostech.com/?apps=" + url,
          //Type of file, in that case acept all audio formats
        },
      });

      axios
        .get(
          localStorage.getItem("analyticsUrl") + "&dp=%2FopenedStore/" + appName
        )
        .then((response) => {
          console.log(response);
        });
    },
    loadExtraTab() {
      document.getElementsByClassName(
        "kaiui-tabs-header"
      )[0].style.zIndex = 999;
      setTimeout(() => {
        var flag = Utils.getFeatureFlag("show_releated_app_list");
        console.log(flag);
        if (flag.value == true || flag.value == false) {
          this.shouldShowReleatedAppTab = flag.value;
          if (flag.value == true) {
            console.log(Utils.getListOfReleatedApp());
            this.releatedList = [];
            Utils.getListOfReleatedApp().forEach((element) => {
              let translation = JSON.parse(element.translation);
              if (translation[Utils.getInStorage("userLanguage")]) {
                element.translationDescription =
                  translation[Utils.getInStorage("userLanguage")];
              } else {
                element.translationDescription = translation["en"];
              }
              this.releatedList.push(element);
            });
          }
        }
      }, 3000);
    },
  },
  computed: {
    tabName: function () {
      if (this.shouldShowReleatedAppTab == true) {
        return this.$t("MESSAGES.moreApps");
      } else {
        return "";
      }
    },
  },
  mounted() {
    if (this.$route.query.from === "all") {
      this.goToTab(3);
    }
    if (this.$route.query.from === "topdownloads") {
      this.goToTab(2);
    }
    if (this.$route.query.from === "tag") {
      this.goToTab(1);
    }
    if (this.$route.query.from === "search") {
      this.$router.push({
        path: "search",
      });
    }
    if (this.$route.query.from === "lastupdates") {
      this.goToTab(0);
    }
    this.loadExtraTab();
  },
};
</script>

<style>
:root {
  --primary-color: #b06cce;
  --primary-dark-color: #8c53c7;
}
</style>
