import { render, staticRenderFns } from "./TabItem.vue?vue&type=template&id=31d1b1cd&"
import script from "./TabItem.vue?vue&type=script&lang=js&"
export * from "./TabItem.vue?vue&type=script&lang=js&"
import style0 from "./TabItem.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../kaios-mobile-wallpaper-pro-2.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports