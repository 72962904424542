<template>
  <div style="height: 100%">
    <kaiui-button
      v-if="showMoreButton"
      :title="$t('MESSAGES.go_to_end')"
      v-on:softCenter="bottomPage()"
      v-on:softRight="openAd()"
      v-on:softLeft="openSearch()"
      v-bind:softkeys="softkeysPhone"
    />
    <div v-for="(wallpaper, index) in wallpapers" :key="index">
      <kaiui-list-item-with-image
        :primaryText="wallpaper.name"
        :secondaryText="wallpaper.tags"
        :imageUrl="wallpaper.url"
        :topColor="wallpaper.topColor"
        :bottomColor="wallpaper.bottomColor"
        v-on:softRight="openAd()"
        v-on:softLeft="openSearch()"
        v-on:softCenter="openDetail(wallpaper)"
        v-bind:softkeys="softkeysPhone"
      ></kaiui-list-item-with-image>
    </div>
    <kaiui-button
      v-if="showMoreButton"
      :title="$t('MESSAGES.show_more')"
      v-on:softCenter="getMore"
      v-on:softRight="openAd()"
      v-on:softLeft="openSearch()"
      v-bind:softkeys="softkeysPhone"
    />
    <div
      style="
        width: 240px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <b-spinner
        v-if="!showMoreButton"
        class="spinner"
        label="Spinning"
        style="margin: auto"
      ></b-spinner>
    </div>
    <div v-if="wallpapers.length == 0" class="spinnercenter">
      <b-spinner
        class="spinner"
        label="Spinning"
        style="margin: auto"
      ></b-spinner>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import utils from "../utils/index";
import navigation from "../../../vue-kaiui-adapted/src/navigation/Navigation";

export default {
  data() {
    return {
      pg: 0,

      softkeysPhone: {
        left: "🔍",
        center: this.$t("MESSAGES.enter"),
        right: this.$t("MESSAGES.ad"),
      },
      wallpapers: Array,
      showMoreButton: true,
    };
  },

  methods: {
    openDetail(wallpaper) {
      utils.openDeatailPage(wallpaper, this.$router, "all");
    },
    openSearch() {
      this.$router.push({
        path: "search",
        query: { from: "all" },
      });
    },
    openAd() {
      //ad is defined in lib project as global variable os app
      window.ad.call("click");
    },
    up() {
      navigation.Up();
    },
    down() {
      navigation.Down();
    },
    async bottomPage() {
      this.$nextTick(async function () {
        document
          .querySelectorAll("[nav-selectable]")
          [this.pg * 10].setAttribute("nav-index", this.pg * 10);
        document
          .querySelectorAll("[nav-selectable]")
          [this.pg * 10].setAttribute("nav-selected", "true");
        document.querySelectorAll("[nav-selectable]")[this.pg * 10].focus();

        //this.up();
      });
      const tempvaluetoupdateview = this.showMoreButton;
      this.showMoreButton = !this.showMoreButton;

      this.$nextTick(async function () {
        this.showMoreButton = !this.showMoreButton;
      });
      utils.logEvent("interaction", "click", "goToEnd");
    },
    getMore() {
      document
        .querySelectorAll("[nav-selectable]")
        [this.wallpapers.length].setAttribute(
          "nav-index",
          this.wallpapers.length
        );
      document
        .querySelectorAll("[nav-selectable]")
        [this.wallpapers.length].setAttribute("nav-selected", "true");
      document
        .querySelectorAll("[nav-selectable]")
        [this.wallpapers.length].focus();
      this.up();
      this.down();
      let pg = this.pg;
      this.pg++;
      this.showMoreButton = false;
      axios
        .get("https://apps.kaios.com.br:9081/api/consumer/allPaginate/" + pg)
        .then((response) => {
          this.showMoreButton = true;

          let teste1 = this.wallpapers;
          this.wallpapers = response.data.wallpapers;
          let teste2 = response.data.wallpapers;
          Array.prototype.push.apply(teste1, teste2);
          this.wallpapers = teste1;
          this.$nextTick(async function () {
            await this.sleep(200);
            this.down();
            this.up();
          });
          utils.logAnalyticsPageView("all", "?pg=" + pg);
        });
      utils.showFullAd();
      utils.logEvent("interaction", "click", "showMore");
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
  },
  mounted() {
    this.$router.push({ path: "all" });
    let pg = this.pg;

    this.pg++;
    this.wallpapers = null;
    axios
      .get("https://apps.kaios.com.br:9081/api/consumer/allPaginate/" + pg)
      .then((response) => {
        this.wallpapers = response.data.wallpapers;
        utils.logAnalyticsPageView("all");
        this.$nextTick(function () {
          utils.selectFirstItemFromTab();
        });
      });
    utils.showFullAd();
  },
};
</script>

<style scoped>
</style>