<template>
  <div style="height: 100%">
    <div v-for="(wallpaper, index) in wallpapers" :key="index">
      <kaiui-list-item-with-image
        :primaryText="wallpaper.name"
        :secondaryText="['TOP' + (index + 1)]"
        :imageUrl="wallpaper.url"
        :topColor="wallpaper.topColor"
        :bottomColor="wallpaper.bottomColor"
        v-on:softRight="openAd()"
        v-on:softLeft="openSearch()"
        v-on:softCenter="openDetail(wallpaper)"
        v-bind:softkeys="softkeysPhone"
      ></kaiui-list-item-with-image>
    </div>
    <div v-if="wallpapers == null" class="spinnercenter">
      <b-spinner
        class="spinner"
        label="Spinning"
        style="margin: auto"
      ></b-spinner>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import utils from "../utils/index";

export default {
  data() {
    return {
      softkeysPhone: {
        left: "🔍",
        center: this.$t("MESSAGES.enter"),
        right: this.$t("MESSAGES.ad"),
      },
      wallpapers: null,
    };
  },

  methods: {
    openDetail(wallpaper) {
      utils.openDeatailPage(wallpaper, this.$router, "topdownloads");
    },
    openAd() {
      //ad is defined in lib project as global variable os app
      window.ad.call("click");
    },
    openSearch() {
      this.$router.push({
        path: "search",
        query: { from: "topdownloads" },
      });
    },
  },
  mounted() {
    this.$router.push({ path: "topdownloads" });

    this.wallpapers = null;
    axios
      .get("https://apps.kaios.com.br:9081/api/consumer/topDownloads")
      .then((response) => {
        this.wallpapers = response.data.wallpapers;
        utils.logAnalyticsPageView("topdownloads");
        this.$nextTick(function () {
          utils.selectFirstItemFromTab();
        });
      });
    document.getElementById("headertitle").firstElementChild.innerText =
      utils.getInStorage("appName");
  },
};
</script>

<style scoped>
</style>
