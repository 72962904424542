<template>
  <div
    style="outline: none;"


    v-on:click="onClick"
  >
    <slot></slot>
  </div>
</template>

<script>
import Shimmer from "./Shimmer.vue";

export default {
  components: { Shimmer },
  name: "kaiui-generic",
  data() {
    return {
      isLoaded: false,
    };
  },
  computed: {
    primaryTextWithoutDash() {
      return (
        this.primaryText.charAt(0).toUpperCase() +
        this.primaryText.slice(1).replaceAll("-", " ")
      );
    },
    secondaryTextProcessed() {
      let textProcessed = "";
      this.secondaryText.forEach((element) => {
        textProcessed += "#" + element + " ";
      });
      return textProcessed;
    },
  },
  props: {
    /**
     * The Softkeys Object
     * @type {{ left: String, center: String, right: String }}
     * @default { center: "Select" }
     */
    softkeys: {
      default: () => ({ center: "Select" }),
      type: Object,
      required: false,
    },
  },
  mounted() {
    this.$on("softkey-left-pressed", () => {
      /**
       * Emit the event `softLeft` when left softkey is selected
       */
      this.$emit("softLeft");
    });
    this.$on("softkey-right-pressed", () => {
      /**
       * Emit the event `softRight` when right softkey is selected
       */
      this.$emit("softRight");
    });
    this.$on("softkey-center-pressed", () => {
      /**
       * Emit the event `softCenter` when center softkey is selected
       */
      this.$emit("softCenter");
    });
  },

  methods: {
    /**
     * @private
     */
    handleFocusChange(isNowFocused) {
      if (isNowFocused) {
        /**
         * @private
         */
        this.$root.$emit("update-softkeys-register", this);
      } else {
        /**
         * @private
         */
        this.$root.$emit("update-softkeys-unregister");
      }
    },
    /**
     * @private
     */
    onClick() {
      this.handleFocusChange(true);
      /**
       * @private
       */
      this.$root.$emit("set-element-selected", this.$el);
    },
  },
};
</script>

<style scoped></style>
