import Vue from 'vue'
import App from './App.vue'
import { SpinnerPlugin, ImagePlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueKaiUI from "../../vue-kaiui-adapted/src/plugin";
let i18nFile = require('../../vue-kaiui-adapted/src/i18n');
import router from '@/router';
import { v4 as uuidv4 } from 'uuid';
import VueI18n from 'vue-i18n';
import utils from './utils';

main();

function main() {

  Vue.use(VueKaiUI); // !
  Vue.use(SpinnerPlugin);
  Vue.use(ImagePlugin);
  Vue.use(VueAxios, axios);
  setBasicInformations();
  setAppRoutines();
  var i18n = getUserLanguage();
  new Vue({ render: (h) => h(App), router, i18n }).$mount('#app');
}

function getAppVersion(callback) {

  var xobj = new XMLHttpRequest();
  xobj.overrideMimeType("application/json");
  xobj.open('GET', 'manifest.webapp', true); // Replace 'my_data' with the path to your file
  xobj.onreadystatechange = function () {
    if (xobj.readyState == 4 && xobj.status == "200") {
      // Required use of an anonymous callback as .open will NOT return a value but simply returns undefined in asynchronous mode
      callback(xobj.responseText);
    }
  };
  xobj.send(null);
}



// |     |    |    |
// v     v    V    V
function setBasicInformations() {
  utils.setInStorage('appName', 'Wallpaper Pro')
  utils.setInStorage('internalAppName', 'wallpaperpro')
  utils.setInStorage('publisherId', '752acc98-e416-4dff-b81c-a1c8634b2e95')
  utils.setInStorage('analyticsUrl', 'https://www.google-analytics.com/collect?v=1&tid=UA-168098621-1&t=pageview&cid=' +
    uuidv4())
  utils.getAppConfig(utils.getInStorage('internalAppName'))
  getAppVersion((result) => {
    utils.setInStorage('appVersion', JSON.parse(result)['version'])

  })
}

function setAppRoutines() {
  utils.startBannerTimeout();
  try {

    navigator.requestWakeLock('screen'); //keep screen on
  } catch (err) {

  }
}

function getUserLanguage() {
  Vue.use(VueI18n);

  var userLanguage = (
    (navigator.languages && navigator.languages[0]) ||
    ''
  ).substr(0, 2);


  if (!userLanguage) {
    userLanguage = 'en';
  }

  utils.setInStorage('userLanguage', userLanguage)


  const i18n = new VueI18n({
    locale: userLanguage, // set locale
    fallbackLocale: 'en',
    messages: i18nFile,
  });

  return i18n
}
