<template>
  <div
    id="releated"
    style="
      display: flex;
      flex-direction: row;
      min-height: 0;
      flex: 1;
      overflow: hidden;
    "
  >
    <div
      v-if="isShowingOverlay"
      style="
        position: fixed;
        background: rgba(3, 3, 3, 0.94) none repeat scroll 0% 0%;
        width: 100%;
        height: 100%;
        z-index: 1001;
        padding-bottom: 30px;
      "
    >
      <div
        style="
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 100%;
          height: 100%;

          align-content: center;
          justify-content: space-between;
        "
      >
        <span
          style="
            font-style: normal;
            font-weight: normal;
            font-size: 25px;
            line-height: 35px;
            text-align: center;
            color: #ffffff;
          "
          >{{ translation.translationDescriptionm.ad_title }}</span
        ><img
          style="
            margin-top: 10px;
            border-radius: 24px;
            width: 60px;
            height: 60px;
          "
          v-show="isLoaded"
          thumbnail
          fluid
          height="156"
          download
          class="img-props"
          @load="onImgLoad"
          :src="translation.appIconUrl"
        />
        <span
          style="
            font-size: 15px;
            text-align: center;
            font-style: normal;
            font-weight: normal;
            color: #ffffff;
          "
          >{{ translation.translationDescriptionm.ad_description }}</span
        >

        <kaiui-button
          :style="{
            backgroundColor: '#' + translation.mainColor,
          }"
          class="adbtn"
          id="ctabtn"
          :isShowingOverlay="isShowingOverlay"
          isOverlayObject="true"
          v-bind:softkeys="softkeysPhone2"
          :title="translation.translationDescriptionm.cta"
          v-on:softLeft="closeModal()"
          v-on:softCenter="openStore(translation.manifestUrl)"
        />
      </div>
    </div>

    <kaiui-generic
      v-bind:softkeys="softkeysPhone"
      v-on:softLeft="back()"
      v-on:softCenter="down()"
      v-on:softRight="openAd()"
      :isShowingOverlay="isShowingOverlay"
      isOverlayObject="false"
      class="css-selector"
      :style="{
        background:
          'linear-gradient(270.71deg, #' +
          this.wallpaper.topColor +
          ' -24.9%, #' +
          this.wallpaper.bottomColor +
          ' 50.18%)',
      }"
      style="width: 100%; height: 119px; mix-blend-mode: normal"
    >
      <div style="width: 100%; height: 100%; text-align: center">
        <div
          style="
            width: 100%;
            height: 180px; 
            mix-blend-mode: normal;
            border-radius: 24px;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <span
            class="kaiui-p_pri kaiui-listitem-primary-text span-adapted"
            style="
              margin-bottom: 10px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              width: 90%;
              display: inline-block;
            "
            >{{ this.wallpaper.name }}</span
          >
          <div style="display: inline-grid">
            <kaiui-shimmer
              v-show="!isLoaded"
              width="117"
              height="156"
              class="img-props"
              style="margin-top: 10px; border-radius: 24px"
            />
            <b-spinner
              v-show="!isLoaded"
              variant="primary"
              label="Spinning"
              style="margin: auto; margin-top: 10px"
            ></b-spinner>
          </div>

          <div style="display: list-item">
            <img
              style="margin-top: 10px; border-radius: 24px"
              v-show="isLoaded"
              thumbnail
              fluid
              height="156"
              download
              class="img-props"
              @load="onImgLoad"
              :src="wallpaper.url"
            />

            <kaiui-button
              :isShowingOverlay="isShowingOverlay"
              v-if="isLoaded"
              isOverlayObject="false"
              v-bind:softkeys="softkeysPhone"
              :title="$t('MESSAGES.set_wallpaper')"
              v-on:softLeft="back()"
              v-on:softCenter="setWallpaper()"
              v-on:softRight="openAd()"
              id="wallpaper"
            /><kaiui-button
              :isShowingOverlay="isShowingOverlay"
              v-if="isLoaded"
              isOverlayObject="false"
              v-bind:softkeys="softkeysPhone"
              :title="$t('MESSAGES.save_gallery')"
              v-on:softLeft="back()"
              v-on:softCenter="saveOnGalery()"
              v-on:softRight="openAd()"
              id="galery"
            />

            <kaiui-header
              class="span-adaptedx"
              :title="this.$t('MESSAGES.you_may_like')"
              :style="{
                background:
                  'linear-gradient(327deg, #' +
                  this.wallpaper.topColor +
                  ' -190%, #3c3b3b 49.87%)',
              }"
            />

            <kaiui-list-item-with-image
              isOverlayObject="false"
              :isShowingOverlay="isShowingOverlay"
              v-if="isLoaded"
              :primaryText="releated.name"
              :secondaryText="releated.tags"
              :imageUrl="releated.url"
              :topColor="releated.topColor"
              :bottomColor="releated.bottomColor"
              v-on:softRight="openAd()"
              v-on:softLeft="back()"
              v-on:softCenter="openDetail(wallpaper)"
              v-bind:softkeys="softkeysPhone"
            ></kaiui-list-item-with-image>
          </div>
        </div>
      </div>
    </kaiui-generic>
  </div>
</template>

<script>
import axios from "axios";
import utils from "../utils";
import navigation from "../../../vue-kaiui-adapted/src/navigation/Navigation";

export default {
  components: {},

  data() {
    return {
      wallpaper: null,
      isLoaded: false,
      tagOfMainImage: "",
      releated: "",
      isShowingOverlay: 0,
      translation: "",

      softkeysPhone: {
        left: this.$t("MESSAGES.back"),
        center: this.$t("MESSAGES.enter"),
        right: this.$t("MESSAGES.ad"),
      },
      softkeysPhone2: {
        left: this.$t("MESSAGES.back"),
        center: this.$t("MESSAGES.enter"),
      },
    };
  },

  async mounted() {
    this.wallpaper = this.$route.params.wallpaper;
    this.tagOfMainImage =
      this.wallpaper.tags[this.getRandomInt(0, this.wallpaper.tags.length)];
    this.getReleatedRingtone();
    this.hideHeader();
    var output = "?";
    for (var property in this.$route.query) {
      if (
        this.$route.query[property] != null &&
        this.$route.query[property] != "undefined"
      )
        output += property + "=" + this.$route.query[property] + "?";
    }
    utils.logAnalyticsPageView("details", output);
  },
  created() {
    window.addEventListener("keydown", this.keyDownHandler);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDownHandler);
  },
  methods: {
    keyDownHandler: function (event) {
      console.log(event.key);
      if (event.key == "Backspace") {
        this.back();
      }
    },
    down() {
      navigation.Down();
      utils.logEvent("interaction", "click", "detailImage");
    },
    back() {
      this.showHeader();

      if (this.$route.query.from) {
        if (this.$route.query.from == "taglist") {
          this.$router.push({ path: "/tag/" + this.$route.query.tagname });
        } else if (this.$route.query.from == "search") {
          this.$router.push({
            path: "/search",
            query: {
              search: this.$route.query.search,
              origin: this.$route.query.origin,
            },
          });
        } else {
          this.$router.push({
            path: "/",
            query: { from: this.$route.query.from },
          });
        }
      }
    },
    openDetail() {
      this.showHeader();
      let wallpaperWithGoodQuality = this.releated;
      wallpaperWithGoodQuality.url = wallpaperWithGoodQuality.url.replace(
        "t_media_lib_thumb",
        "c_fill,h_320,w_240"
      );
      wallpaperWithGoodQuality.name =
        wallpaperWithGoodQuality.name.charAt(0).toUpperCase() +
        wallpaperWithGoodQuality.name.slice(1).replaceAll("-", " ");
      const origin = this.$route.query.from;
      const search = this.$route.query.search;
      const tagName = this.$route.query.tagname;
      const realOrigin = this.$route.query.origin;

      this.$router
        .push({
          path: "/empty",
        })
        .then((response) => {
          utils.openDeatailPage(
            wallpaperWithGoodQuality,
            this.$router,
            origin,
            tagName,
            search,
            realOrigin,
            true
          );
        });
      utils.showFullAd();
    },
    openAd() {
      //ad is defined in lib project as global variable os app
      window.ad.call("click");
    },
    shouldShowReleatedApp() {
      var shouldShowReleatedApp = utils.getFeatureFlagValue(
        "show_releated_app_ad"
      );
      return shouldShowReleatedApp;
    },
    async openReleatedAp() {
      if (this.shouldShowReleatedApp()) {
        try {
          this.hideAdsContainer();
        } catch (error) {}

        this.$nextTick(function () {
          this.isShowingOverlay = 1;
          this.translation = utils.getReleatedAppForAd();

          if (this.translation == null) {
            this.isShowingOverlay = 0;
          }

          this.$nextTick(async function () {
            // await this.sleep(2000);
            // document.getElementById("releated").style.display = "block";
            // await this.sleep(800);
            // document.getElementById("releated").style.display = "flex";
            // await this.sleep(200);

            document.getElementById("ctabtn").click();
            utils.logAnalyticsPageView(
              "releated",
              "?app=" + this.translation.appName
            );
            utils.logEvent("ads", "show", this.translation.appName);
          });
        });
      } else {
      }
    },
    openStore(url) {
      utils.logEvent("ads", "click", this.translation.appName);

      this.closeModal();

      var activity = new MozActivity({
        name: "open-deeplink",
        data: {
          //The blob object of audio file, must be within in array
          url: "app://kaios-store.kaiostech.com/?apps=" + url,
          //Type of file, in that case acept all audio formats
        },
      });
    },
    closeModal() {
      this.showAdsContainer();

      this.isShowingOverlay = 0;
      this.$nextTick(function () {
        navigation.Down();
        navigation.Up();
      });
      utils.showFullAd();
    },
    hideHeader() {
      document.getElementsByClassName("kaiui-header")[0].style =
        "height: 0px; min-height: 0px";

      document.getElementsByClassName(
        "kaiui-h1 kaiui-header-text"
      )[0].style.visibility = "hidden";
    },
    showHeader() {
      document.getElementsByClassName("kaiui-header")[0].style =
        "height: 30px; min-height: 30px";
      document.getElementsByClassName(
        "kaiui-h1 kaiui-header-text"
      )[0].style.visibility = "visible";
    },
    hideAdsContainer() {
      document.getElementById("ad-container").style =
        "height: 0px; visibility: hidden";
      document.getElementsByClassName("ads")[0].style = "max-height: 0px";
    },
    showAdsContainer() {
      if (document.getElementById("ad-container").childElementCount > 0) {
        document.getElementById("ad-container").style =
          "height: 60px;max-height: 60px; visibility: visible;background: rgb(255, 255, 255) none repeat scroll 0% 0%; position: relative; width: 240px;";
      } else {
        document.getElementById("ad-container").style =
          "height: 0px;max-height: 60px; visibility: visible;background: rgb(255, 255, 255) none repeat scroll 0% 0%; position: relative; width: 240px;";
      }
      document.getElementsByClassName("ads")[0].style = "max-height: 60px";
    },
    async setWallpaper() {
      try {
        var wallpaper = this.wallpaper.url;

        if (location.protocol === "https:") {
          wallpaper = wallpaper.replace("http", "https");
        }
        console.log("eis que");

        console.log(wallpaper);
        let result = utils.setWallpaper(wallpaper, async () => {
          this.openReleatedAp();
          utils.showFullAd();
        });
        result.onsuccess = function () {};
      } catch (error) {}

      this.increaseActionDownload();
      this.increaseDownload();
      utils.logEvent("interaction", "click", "setWallpaper");
    },
    async saveOnGalery() {
      var wallpaper = this.wallpaper.url;
      console.log("location.protocol");
      console.log(location.protocol);

      if (location.protocol === "https:") {
        wallpaper = wallpaper.replace("http", "https");
      }
      console.log("eis que");

      console.log(wallpaper);

      this.forceDownload(wallpaper, this.wallpaper.name + ".png", async () => {
        this.$nextTick(async function () {
          await this.sleep(500);

          this.openReleatedAp();
          this.increaseDownload();
          this.increaseActionGallery();
          utils.showFullAd();
          utils.logEvent("interaction", "click", "download");

          // await this.sleep(300);
          // document.getElementById("releated").style.display = "block";
          // await this.sleep(800);
          // document.getElementById("releated").style.display = "flex";
          // await this.sleep(200);
        });
      });
    },

    onImgLoad() {
      this.isLoaded = true;
      navigation.Up();
    },
    getReleatedRingtone() {
      axios
        .get(
          "https://apps.kaios.com.br:9081/api/consumer/tag/" +
            this.tagOfMainImage
        )
        .then((response) => {
          let lengthRings = response.data.wallpapers.length;
          this.releated =
            response.data.wallpapers[this.getRandomInt(0, lengthRings)];
        });
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min;
    },

    forceDownload(url, fileName, callback) {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = async function () {
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(this.response);
        var tag = document.createElement("a");
        tag.href = imageUrl;
        tag.download = fileName;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);

        navigation.Down();

        callback();
      };
      xhr.send();
    },
    increaseDownload() {
      axios
        .get(
          "https://apps.kaios.com.br:9081/api/consumer/increment/wallpaper/" +
            this.wallpaper._id
        )
        .then((response) => console.log(response));
    },
    increaseActionDownload() {
      axios
        .get(
          "https://apps.kaios.com.br:9081/api/consumer/increment/wallpaperActionDownload/" +
            this.wallpaper._id
        )
        .then((response) => console.log(response));
    },
    increaseActionGallery() {
      axios
        .get(
          "https://apps.kaios.com.br:9081/api/consumer/increment/wallpaperActionGallery/" +
            this.wallpaper._id
        )
        .then((response) => console.log(response));
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },

    timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>

<style scoped>
.css-selector[nav-selected="true"] img {
  border: 5px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 24px !important;
}
.img-props[nav-selected="true"] {
  box-sizing: border-box;
}

.span-adapted {
  mix-blend-mode: difference;
  color: white !important;
}

.css-selector[nav-selected="true"] {
  background-size: 250% 250% !important;

  -webkit-animation: AnimationName 5s cubic-bezier(0.68, 1.01, 0, 0.62) infinite !important;
  -moz-animation: AnimationName 5s cubic-bezier(0.68, 1.01, 0, 0.62) infinite !important;
  animation: AnimationName 5s cubic-bezier(0.68, 1.01, 0, 0.62) infinite !important;
}

.adbtn {
  width: 90%;
}
.adbtn[nav-selected="true"] {
  border: 2px solid var(--header-background-color);
  box-sizing: border-box;
}

@keyframes AnimationName {
  0% {
    background-position: 0% 100%;
  }

  50% {
    background-position: 100% 100%;
  }

  100% {
    background-position: 0% 100%;
  }
}
</style>
