<template>
  <div style="height: 100%"></div>
</template>

<script>
import axios from "axios";
import utils from "../utils/index";
import navigation from "../../../vue-kaiui-adapted/src/navigation/Navigation";

export default {
  data() {
    return {};
  },

  methods: {},
  mounted() {},
};
</script>

<style scoped>
</style>