import axios from "axios";

// https://stackoverflow.com/a/2117523
const uuid = () => {
  return `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

const setWallpaper = (arr, callback) => {
  var xhr = new XMLHttpRequest({
    mozSystem: true,
  });
  xhr.open("GET", arr, true);
  xhr.responseType = "blob";

  xhr.onload = function () {
    //sample activity
    var activity = new MozActivity({
      name: "setwallpaper",
      data: {
        type: "image/*",
        number: 1,
        blobs: [this.response],
        filenames: ["wallpapertest.png"],
      },
    });

    activity.onsuccess = function () {
      callback();
    };

    activity.onerror = function (error) {
      console.log("Error to call activity", error);
    };

    console.log(activity);
  };
  xhr.onerror = function () {
    alert("Error with System XHR");
  };
  xhr.send();
};

const setWallpaperWithoutAsk = (arr, callback) => {
  var xhr = new XMLHttpRequest({
    mozSystem: true,
  });
  xhr.open("GET", arr, true);
  xhr.responseType = "blob";

  xhr.onload = function () {


    let domRequest = navigator.mozSettings.createLock().set({
      "wallpaper.image": this.response,
    });

    domRequest.onsuccess = () => {
      console.debug('Wallpaper Set');
      callback();

    };

    domRequest.onerror = (e) => {
      console.debug('Cannot Set Wallpaper', e);
      onError(e);

      callback();
    };

  };
  xhr.onerror = function () {
    alert("Error with System XHR");
  };
  xhr.send();
};

const shareImage = async (arr) => {

  var xhr = new XMLHttpRequest({
    mozSystem: true,
  });
  xhr.open('GET', arr, true);
  xhr.responseType = 'blob';

  xhr.onload = function () {
    //sample activity
    try {
      var activity = new MozActivity({
        name: 'share',
        data: {
          type: 'image/*',
          number: 1,
          blobs: [this.response],
          filenames: ['meme.png'],
        },
      });
      console.log(activity);
      logEvent("success_share_image", '')
    } catch (err) {
      logEvent("error_share_image", '');
    }

  };
  xhr.onerror = function () {
    alert('Error with System XHR');
    logEvent("error_share_image", '');

  };
  xhr.send();
  return true;


}

const setRingtone = async (arr, name, artist, blobimage) => {
  console.log('teste');
  var xhr = new XMLHttpRequest({
    mozSystem: true,
  });
  xhr.open('GET', arr, true);
  xhr.responseType = 'blob';

  xhr.onload = function () {
    //sample activity
    var activity = new MozActivity({
      name: 'setringtone',
      data: {
        //The blob object of audio file, must be within in array
        blobs: [this.response],
        //Type of file, in that case acept all audio formats
        type: 'audio/*',
        //The metadata of audio, these are the 3 available properties
        metadata: [{
          title: name,
          artist: artist,
          picture: blobimage,
        },],
      },
    });
    console.log(activity);
  };
  xhr.onerror = function () {
    alert('Error with System XHR');
  };
  xhr.send();
  return true;
}

const loadBanner = (appName, publisher, timeout) => {
  try {
    getKaiAd({
      publisher: publisher,
      app: appName,
      slot: "ALL",

      h: 60,
      w: 240,
      timeout: timeout ? timeout : 60000,
      // Max supported size is 240x264
      // container is required for responsive ads
      container: document.getElementById("ad-container"),

      onerror: (err) => afterAdLoad(),
      onready: (ad) => {
        try {
          window.ad = ad;
        } catch (error) {
          console.log(error);
        }
        console.log("isready");
        // Ad is ready to be displayed
        // calling 'display' will display the ad
        ad.call("display", {
          // In KaiOS the app developer is responsible
          // for user navigation, and can provide
          // navigational className and/or a tabindex
          tabindex: 0,
          // if the application is using
          // a classname to navigate
          // this classname will be applied
          // to the container
          navClass: "items",

          // display style will be applied
          // to the container block or inline-block
          display: "inline-block",
        });

        ad.on("display", () => {
          console.log("display ad");
          afterAdLoad();

          var list = document.getElementById("ad-container"); // Get the <ul> element with id="myList"
          if (childNodes.length > 1) {
            list.removeChild(list.childNodes[0]);
          }
        });

        ad.on("click", () => console.log("click adas event"));
      },
    });
  } catch (err) {
    console.error("could not load ads sdk");
  }
};

const loadAdFullScreen = (appName, publisher) => {
  try {
    getKaiAd({
      publisher: publisher,
      app: appName,
      slot: "ALL",

      onerror: (err) => console.error("Custom catch:", err),
      onready: (ad) => {
        // Ad is ready to be displayed
        // calling 'display' will display the ad
        ad.call("display");
      },
    });
  } catch (error) {
    console.error("could not load ads sdk");
  }
};

async function afterAdLoad() {
  // await sleep(200);
  // document.getElementById("releated").style.display = "block";
  // await sleep(200);
  // document.getElementById("releated").style.display = "flex";
}

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

const setInStorage = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (err) {
    console.error("Could not save in localstorage");
  }
};


const getInStorage = (key) => {
  try {
    return localStorage.getItem(key);
  } catch (err) {
    console.error("Could not get in localstorage");
  }
};

const getAppConfig = (appName) => {
  var url = "http://apps.kaios.com.br:8085/api/consumer/appconfig/appname/";
  if (location.protocol === "https:") {
    url = url.replace("http", "https");
    url = url.replace("8085", "9085");
  }
  try {
    axios
      .get(
        url +
        appName
      )
      .then((response) => {
        setInStorage("appConfig", JSON.stringify(response.data.appConfigs[0]));
        return response.data.appConfigs[0];
      });
  } catch (error) {
    console.error("Could not get in appconfig " + error);
  }
};

const getReleatedAppAd = () => {
  try {
    let appConfig = getInStorage("appConfig");
    appConfig = JSON.parse(appConfig);

    if (appConfig.releatedApps) {
      const countOfReleatedApp = appConfig.releatedApps.length;
      if (countOfReleatedApp == 1) {
        return appConfig.releatedApps[0];
      } else {
        return makeElection(appConfig.releatedApps);
      }
    }
    return null;
  } catch (err) {
    return null;
  }
};

function getReleatedAppForAd() {
  let releatedApp = getReleatedAppAd()
  let translation = JSON.parse(releatedApp.translation)
  if (translation[getInStorage('userLanguage')]) {
    releatedApp.translationDescriptionm = translation[getInStorage('userLanguage')]
  } else {
    releatedApp.translationDescriptionm = translation['en']
  }
  return releatedApp;
}



const getListOfReleatedApp = () => {
  try {
    let appConfig = getInStorage("appConfig");
    appConfig = JSON.parse(appConfig);

    if (appConfig.releatedApps) {
      return appConfig.releatedApps;
    }
    return null;
  } catch (err) {
    return null;
  }
};

const getFeatureFlag = (key) => {
  try {
    let appConfig = getInStorage("appConfig");
    appConfig = JSON.parse(appConfig);
    if (appConfig.featureFlag) {
      if (appConfig.featureFlag.length >= 1) {
        var featureFlag = appConfig.featureFlag.find((x) => x.key === key);

        return featureFlag;
      }
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

const goToTab = (index) => {

  document
    .querySelectorAll("[tab-selectable]")
  [index].setAttribute("tab-selected", "true");

  document
    .querySelectorAll("[tab-selectable]")[0]
    .setAttribute("tab-selected", "false");

  document.querySelectorAll("[tab-selectable]")[index].click();

};

const showHeader = () => {
  document.getElementsByClassName("kaiui-header")[0].style =
    "height: 30px; min-height: 30px";
  document.getElementsByClassName(
    "kaiui-h1 kaiui-header-text"
  )[0].style.visibility = "visible";
};

const hideHeader = () => {
  document.getElementsByClassName("kaiui-header")[0].style =
    "height: 0px; min-height: 0px";

  document.getElementsByClassName(
    "kaiui-h1 kaiui-header-text"
  )[0].style.visibility = "hidden";
};

function makeElection(releatedApps) {
  let releatedAppsWithWeightAdjustment = [];

  releatedApps.forEach((element) => {
    if (element.weight != 0) {
      for (let index = 0; index < element.weight; index++) {
        releatedAppsWithWeightAdjustment.push(element);
      }
    }
  });
  var randomValue = getRandomInt(0, releatedAppsWithWeightAdjustment.length);

  return releatedAppsWithWeightAdjustment[randomValue];
}

const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
};

const logPageView = () => {
  sendAnalytics('page_view', { 'page_title': window.location.pathname + window.location.search, });
  console.log(' ****** PAGEVIEW_EVENT: ' + window.location.pathname + window.location.search);
}

function sendAnalytics(eventName, parameters) {
  const measurementId = localStorage.getItem('analyticsId');
  const apiSecret = localStorage.getItem('analytics_measurement_protocol');
  const userId = localStorage.getItem('analytics_user_id');

  fetch(`https://www.google-analytics.com/mp/collect?measurement_id=${measurementId}&api_secret=${apiSecret}`, {
    method: "POST",
    body: JSON.stringify({
      "client_id": userId,
      "user_id": userId,
      "events": [{
        "name": eventName,
        "params": {
          ...parameters
        }
      }]
    })
  });
}

const logEvent = async (eventName, mapOfParameters) => {
  try {
    const appVersion = getInStorage("appVersion");
    var params = {
      'app_version': appVersion,
      ...mapOfParameters,

    }
    var battery_level;
    var battery_charging;
    const battery = await navigator.getBattery();
    try {
      battery_level = battery.level;
      battery_charging = battery.charging;
      params = {
        ...params,
        'battery_level': battery_level,
        'battery_charging': battery_charging,

      }

    } catch (error) {
      console.log(error)
    }
    try {
      sendAnalytics(eventName, params);
    } catch (error) {
      console.log(error)
    }

    console.log(' ****** EVENT: ' + eventName);
  } catch (error) {
    sendAnalytics(eventName, params);
  }

}


const getAppVersion = (callback) => {

  var xobj = new XMLHttpRequest();
  xobj.overrideMimeType("application/json");
  xobj.open('GET', 'manifest.webapp', true); // Replace 'my_data' with the path to your file
  xobj.onreadystatechange = function () {
    if (xobj.readyState == 4 && xobj.status == "200") {
      // Required use of an anonymous callback as .open will NOT return a value but simply returns undefined in asynchronous mode
      callback(xobj.responseText);
    }
  };
  xobj.send(null);
}

const selectFirstItemFromTab = () => {

  document
    .querySelectorAll("[nav-selectable]")[0]
    .setAttribute("nav-index", "0");
  document
    .querySelectorAll("[nav-selectable]")[0]
    .setAttribute("nav-selected", "true");
  document.querySelectorAll("[nav-selectable]")[0].focus();

}


const newOpenDetailPage = (appName, item, route, from, tagName, search, origin, isFromRelated) => {
  if (appName == "wallpaperpro") {
    openDetailWallpaperPro(item, route, from, tagName, search, origin, isFromRelated)
  }
  if (appName == "livepaperspro") {
    openDetailWallpaperPro(item, route, from, tagName, search, origin, isFromRelated)
  }
  if (appName == "zapoffun") {
    openDetailZapOfFun(item, route, from, tagName, search, origin, isFromRelated)
  }
  if (appName == "ringtonespro") {
    openDetailRingtones(item, route, from, tagName, search, origin, isFromRelated)
  }

}


function openDetailZapOfFun(item, route, from, tagName, search, origin, isFromRelated) {
  let wallpaperWithGoodQuality = item;
  wallpaperWithGoodQuality.url = wallpaperWithGoodQuality.url.replace(
    "t_media_lib_thumb",
    "c_fill,h_320,w_240"
  );
  wallpaperWithGoodQuality.name =
    wallpaperWithGoodQuality.name.charAt(0).toUpperCase() +
    wallpaperWithGoodQuality.name.slice(1).replaceAll("-", " ");

  let parameters = from ? {
    name: "detailzapoffun",
    params: { name: wallpaperWithGoodQuality },
    query: { name: item.name, from: from, tagname: tagName, search: search, origin: origin, isFromRelated: isFromRelated },
  } : {
    name: "detailzapoffun",
    params: { name: wallpaperWithGoodQuality },
    query: { name: item.name }
  }
  route.push(parameters);

}

function openDetailRingtones(item, route, from, tagName, search, origin, isFromRelated) {
  let wallpaperWithGoodQuality = item;
  wallpaperWithGoodQuality.url = wallpaperWithGoodQuality.url.replace(
    "t_media_lib_thumb",
    "c_fill,h_320,w_240"
  );
  wallpaperWithGoodQuality.name =
    wallpaperWithGoodQuality.name.charAt(0).toUpperCase() +
    wallpaperWithGoodQuality.name.slice(1);
  let parameters = from ? {
    name: "detailringtonespro",
    params: { wallpaper: wallpaperWithGoodQuality },
    query: { name: item.name, from: from, tagname: tagName, search: search, origin: origin, isFromRelated: isFromRelated },
  } : {
    name: "detailringtonespro",
    params: { wallpaper: wallpaperWithGoodQuality },
    query: { name: item.name }
  }
  route.push(parameters);

}

function openDetailWallpaperPro(item, route, from, tagName, search, origin, isFromRelated) {
  let wallpaperWithGoodQuality = item;
  wallpaperWithGoodQuality.url = wallpaperWithGoodQuality.url.replace(
    "t_media_lib_thumb",
    "c_fill,h_320,w_240,fl_lossy,q_80"
  );
  wallpaperWithGoodQuality.name =
    wallpaperWithGoodQuality.name.charAt(0).toUpperCase() +
    wallpaperWithGoodQuality.name.slice(1).replaceAll("-", " ");
  let parameters = from ? {
    name: "detailwallpaperpro",
    params: { wallpaper: wallpaperWithGoodQuality },
    query: { name: item.name, from: from, tagname: tagName, search: search, origin: origin, isFromRelated: isFromRelated },
  } : {
    name: "detailwallpaperpro",
    params: { wallpaper: wallpaperWithGoodQuality },
    query: { name: item.name }
  }
  route.push(parameters);
}

function startWakeLock() {
  try {
    navigator.requestWakeLock('screen');
  } catch (err) {
    console.warn(err);
  }
}


export default {
  uuid,
  setInStorage,
  getInStorage,
  loadBanner,
  getAppConfig,
  setWallpaper,
  setWallpaperWithoutAsk,
  getReleatedAppAd,
  getReleatedAppForAd,
  loadAdFullScreen,
  getFeatureFlag,
  logEvent,
  getAppVersion,
  getListOfReleatedApp,
  setRingtone,
  shareImage,
  goToTab,
  showHeader,
  hideHeader,
  logPageView,
  selectFirstItemFromTab,
  newOpenDetailPage,
  startWakeLock
};