<template>
  <div class="shine" id="sh" :style="style"></div>
</template>

<script>
export default {
  name: "kaiui-shimmer",
  data: () => ({}),
  methods: {},
  computed: {
    style() {
      return (
        "width: " +
        this.width +
        "px !important; height: " +
        this.height +
        "px !important; " +
        "background-size: " +
        this.width * 3 +
        "px " +
        this.height * 3 +
        "px; "
      );
    },
  },
  props: {
    width: {
      required: true,
    },
    height: {
      required: true,
    },
  },
};
</script>

<style scoped>
.shine {
  background: #ededed;
  background-image: linear-gradient(
    to right,
    #ededed 0%,
    #d4d4d7 20%,
    #bfc1c2 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 300px 300px;
  display: inline-block;
  position: relative;

  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
</style>
