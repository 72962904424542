<template>
  <div style="width: 100%;">
    <div
      class="kaiui-listitem css-selector"
      v-bind:nav-selectable="true"
      tabindex="0"
      v-on:focus="handleFocusChange(true)"
      v-on:blur="handleFocusChange(false)"
      v-on:click="onClick"
      style="mix-blend-mode: normal; box-sizing: border-box;"
    >
      <div
        class="text-section"
        style="justify-content: space-between; display: flex; margin: auto;"
      >
        <span class="kaiui-p_pri kaiui-listitem-primary-text">{{
          primaryText
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "kaiui-list-for-tag",
  data() {
    return {};
  },
  computed: {},
  props: {
    /**
     * The Softkeys Object
     * @type {{ left: String, center: String, right: String }}
     * @default { center: "Select" }
     */
    softkeys: {
      default: () => ({ center: "Select" }),
      type: Object,
      required: false,
    },
    primaryText: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.$on("softkey-left-pressed", () => {
      /**
       * Emit the event `softLeft` when left softkey is selected
       */
      this.$emit("softLeft");
    });
    this.$on("softkey-right-pressed", () => {
      /**
       * Emit the event `softRight` when right softkey is selected
       */
      this.$emit("softRight");
    });
    this.$on("softkey-center-pressed", () => {
      /**
       * Emit the event `softCenter` when center softkey is selected
       */
      this.$emit("softCenter");
    });
  },

  methods: {
    /**
     * @private
     */
    handleFocusChange(isNowFocused) {
      if (isNowFocused) {
        /**
         * @private
         */
        this.$root.$emit("update-softkeys-register", this);
      } else {
        /**
         * @private
         */
        this.$root.$emit("update-softkeys-unregister");
      }
    },
    /**
     * @private
     */
    onClick() {
      this.handleFocusChange(true);
      /**
       * @private
       */
      this.$root.$emit("set-element-selected", this.$el);
    },
  },
};
</script>

<style scoped>
.kaiui-listitem-for-tag {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: 0;
}
.kaiui-listitem-for-tag[nav-selected="true"] {
  border: 5px solid var(--header-background-color);
  box-sizing: border-box;
}
</style>
