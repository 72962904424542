<template>
  <div style="height: 100%">
    <div v-for="(wallpaper, id) in wallpapers" :key="id">
      <kaiui-list-item-with-image
        :primaryText="wallpaper.name"
        :secondaryText="wallpaper.tags"
        :imageUrl="wallpaper.url"
        :topColor="wallpaper.topColor"
        :bottomColor="wallpaper.bottomColor"
        v-on:softLeft="openSearch()"
        v-on:softRight="openAd()"
        v-on:softCenter="openDetail(wallpaper)"
        v-bind:softkeys="softkeysPhone"
      ></kaiui-list-item-with-image>
    </div>
    <div v-if="wallpapers == null" class="spinnercenter">
      <b-spinner
        class="spinner"
        label="Spinning"
        style="margin: auto"
      ></b-spinner>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import utils from "../utils/index";

export default {
  data() {
    return {
      softkeysPhone: {
        left: "🔍",
        center: this.$t("MESSAGES.enter"),
        right: this.$t("MESSAGES.ad"),
      },
      wallpapers: null,
    };
  },

  methods: {
    openDetail(wallpaper) {
      utils.openDeatailPage(wallpaper, this.$router, "lastupdates");
    },
    openAd() {
      //ad is defined in lib project as global variable os app
      window.ad.call("click");
    },
    openSearch() {
      this.$router.push({
        path: "search",
        query: { from: "lastupdates" },
      });
    },
  },
  mounted() {
    this.wallpapers = null;
    if (!this.$route.query.from) {
      this.$router.push({ path: "lastupdates" });
      axios
        .get(localStorage.getItem("analyticsUrl") + "&dp=%2FlastUpdate")
        .then((response) => {});
    }

    axios
      .get("https://apps.kaios.com.br:9081/api/consumer/last")
      .then((response) => {
        this.wallpapers = response.data.wallpapers;
        utils.logAnalyticsPageView("lastUpdate");
        this.$nextTick(function () {
          utils.selectFirstItemFromTab();
        });
      });
    utils.showFullAd();
  },
};
</script>

<style scoped>
</style>
