<template>
  <div>
    <kaiui-list-for-tag
      v-for="(tag, id) in tags"
      :key="id"
      :primaryText="'#' + tag"
      v-bind:softkeys="softkeysPhone"
      v-on:softCenter="openDetail(tag)"
      v-on:softRight="openAd()"
      v-on:softLeft="openSearch()"
    ></kaiui-list-for-tag>
  </div>
</template>

<script>
import axios from "axios";
import utils from "../utils/index";

export default {
  data() {
    return {
      tags: null,

      softkeysPhone: {
        left: "🔍",
        center: this.$t("MESSAGES.enter"),
        right: this.$t("MESSAGES.ad"),
      },
    };
  },
  components: {},
  mounted() {
    this.$router.push({ path: "tag" });

    axios
      .get("https://apps.kaios.com.br:9081/api/consumer/getAllTags")
      .then((response) => {
        this.tags = response.data.tags.sort();
        utils.logAnalyticsPageView("tag");
        this.$nextTick(function () {
          utils.selectFirstItemFromTab();
        });
      });
  },
  created() {},
  methods: {
    openAd() {
      //ad is defined in lib project as global variable os app
      window.ad.call("click");
    },
    openDetail(name) {
      this.$router.push("tag/" + name);
    },
    openSearch() {
      this.$router.push({
        path: "search",
        query: { from: "tag" },
      });
    },
  },
};
</script>

<style></style>
